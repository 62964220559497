
import React, { useEffect, useState, useMemo } from 'react';
import { Container, Spinner, Alert, Button, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { getProducerOrderById, updatePackedSkus } from '../../services/producerOrderService';
import { getSkus } from "../../services/skuService";
import { getSkuTitle } from '../../utils/skuUtils';
import { FaArrowLeft } from "react-icons/fa";
import debounce from 'lodash/debounce'; // Ensure lodash is installed

import {
    handleAddPackedSku,
    handleRemovePackedSku,
    handleUpdatePackedSku,
    handleSelectPackedSku
} from '../../utils/packedSkuUtils';
import '../../styles.css';

const ProducerOrderDetails = () => {
    const [producerOrder, setProducerOrder] = useState(null);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [skus, setSkus] = useState([]);
    const [saveStatus, setSaveStatus] = useState("Auto saved");

    // Extract orderId and producerOrderId from URL query params
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const orderId = queryParams.get('orderId');
    const producerOrderId = queryParams.get('producerOrderId');

    const isEditable = ["AWAITING_PROCESSING", "PROCESSING"].includes(producerOrder?.producerOrderStatus);
    console.log("isEditable:", isEditable);

    const renderTooltip = (props) => (
        <Tooltip {...props}>
            You cannot change the packed items once the order status is "{producerOrder?.producerOrderStatus}"
        </Tooltip>
    );

    useEffect(() => {
        const fetchProducerOrder = async () => {
            if (!orderId || !producerOrderId) {
                setError('Missing orderId or producerOrderId in the URL.');
                setIsLoading(false);
                return;
            }

            try {
                const response = await getProducerOrderById(orderId, producerOrderId);
                if (!response.success) {
                    throw new Error('Failed to fetch producer order details.');
                }

                const fetchedProducerOrder = response.producerOrder;

                // Fetch all SKUs
                const skusData = await getSkus();
                const filteredSkus = skusData.filter((sku) => sku.sellerId === fetchedProducerOrder.sellerId);

                setSkus(filteredSkus);
                setProducerOrder(fetchedProducerOrder);
            } catch (error) {
                console.error('Error fetching producer order:', error);
                setError('Failed to load producer order details.');
            } finally {
                setIsLoading(false);
            }
        };

        fetchProducerOrder();
    }, [orderId, producerOrderId]);

    const debouncedSave = useMemo(() =>
        debounce(async (order) => {
            if (!isEditable || !order) return;  // 🚨 Explicitly prevent save if isEditable is false
            setSaveStatus("Saving...");

            try {
                await updatePackedSkus(
                    order.sellerId,
                    order.orderId,
                    order.producerOrderId,
                    order.packedSkus ?? []
                );
                setSaveStatus("Auto saved");
            } catch (error) {
                setSaveStatus("Error saving");
            }
        }, 500),
        [isEditable] // Ensure dependency updates if editability changes
    );

    const getAvailableSkusForPacking = useMemo(() => {
        return (currentPackedSkuId) => {
            const packedSkuIds = new Set(producerOrder?.packedSkus.map((p) => p.packedSkuId).filter(Boolean));
            return skus.filter((sku) => !packedSkuIds.has(sku.skuId) || sku.skuId === currentPackedSkuId);
        };
    }, [producerOrder, skus]);

    const producerOrderPackedSkus = producerOrder?.packedSkus || [];

    if (isLoading) {
        return (
            <Container className="mt-4 text-center">
                <Spinner animation="border">
                    <output className="visually-hidden">Loading producer order...</output>
                </Spinner>
            </Container>
        );
    }

    if (error) {
        return (
            <Container className="mt-4">
                <Alert variant="danger">{error}</Alert>
            </Container>
        );
    }

    if (!producerOrder) {
        return (
            <Container className="mt-4">
                <Alert variant="warning">No producer order found.</Alert>
            </Container>
        );
    }

    return (
        <Container className="content">
            <h1>Producer Order Details</h1>

            <Button className="mb-4 btn-sm back-button" variant="secondary" onClick={() => navigate(`/order-details?orderId=${orderId}`)}>
                <FaArrowLeft className="me-2" style={{ fontSize: "0.875rem" }} />
                Back
            </Button>


            <div className="order-details">
                <p><strong>Order ID:</strong> {producerOrder.orderId}</p>
                <p><strong>Seller:</strong> {producerOrder.seller}</p>
                <p><strong>Buyer Company:</strong> {producerOrder.buyerCompanyName}</p>
                <p><strong>Producer Order ID:</strong> {producerOrder.producerOrderId}</p>
                <p><strong>Status:</strong> {producerOrder.producerOrderStatus}</p>
                <p><strong>Created:</strong> {new Date(producerOrder.createdAt).toLocaleString()}</p>
                <p><strong>Last Updated:</strong> {new Date(producerOrder.updatedAt).toLocaleString()}</p>
            </div>

            {/* Auto-Save Status */}
            {isEditable && (
    <div className="save-status mt-3">
        {saveStatus === "Saving..." && <Spinner animation="border" size="sm" />}
        <span className="ml-2">{saveStatus}</span>
    </div>
)}


            {/* Ordered Items Section */}
            {producerOrder.skus.map((sku) => (
                <div key={sku.skuId} className="order-card">
                    <div className="h3 py-3">Ordered Items</div>
                    <div className="grid-container">
                        <div className="grid-item"><strong>SKU:</strong></div>
                        <div className="grid-item">{getSkuTitle(sku.skuId, skus)}</div>

                        <div className="grid-item"><strong>Volume:</strong></div>
                        <div className="grid-item">{sku.quantity} {sku.uom}</div>

                        <div className="grid-item"><strong>Item Notes:</strong></div>
                        <div className="grid-item">{sku.orderNotes || 'N/A'}</div>

                        <div className="grid-item"><strong>Substitution Notes:</strong></div>
                        <div className="grid-item">{sku.substitutionNotes || 'N/A'}</div>
                    </div>

                    {/* Packed Items Section */}
                    <div className="grid-title">
                        <div className="h3 py-3">Packed Items</div>
                        <OverlayTrigger placement="top" overlay={!isEditable ? renderTooltip : <></>}>
                            <span className="d-inline-block">
                                <Button
                                    variant="success"
                                    size="sm"
                                    className="add-button"
                                    onClick={() => handleAddPackedSku(producerOrder, setProducerOrder, sku.skuId)}
                                    disabled={!isEditable}
                                    style={!isEditable ? { pointerEvents: "none" } : {}}
                                >
                                    [+]
                                </Button>
                            </span>
                        </OverlayTrigger>
                    </div>

                    {producerOrderPackedSkus.some(p => p.skuId === sku.skuId) ? (
                        producerOrderPackedSkus.filter(p => p.skuId === sku.skuId).map((packed, index) => (
                            <div key={index} className="packed-sku-card">
                                <div className="packed-sku-content">



                                    <div className="grid-container">
                                        <div className="grid-item"><strong>SKU:</strong></div>
                                        <div className="grid-item">
                                            <OverlayTrigger placement="top" overlay={!isEditable ? renderTooltip : <></>}>
                                                <span className="d-inline-block">
                                                    <Form.Select
                                                        value={packed.packedSkuId || ""}
                                                        onChange={(e) => handleSelectPackedSku(isEditable, setProducerOrder, packed.packedSkuId, e.target.value, debouncedSave)}
                                                        disabled={!isEditable} // Disable dropdown if isEditable is false
                                                    >
                                                        <option value="" disabled>Select a SKU</option>
                                                        {getAvailableSkusForPacking(packed.packedSkuId).map((s) => (
                                                            <option key={s.skuId} value={s.skuId}>
                                                                {getSkuTitle(s.skuId, skus)}
                                                            </option>
                                                        ))}
                                                    </Form.Select>
                                                </span>
                                            </OverlayTrigger>
                                        </div>

                                        <div className="grid-item"><strong>Volume:</strong></div>
                                        <div className="grid-item">
                                            <OverlayTrigger placement="top" overlay={!isEditable ? renderTooltip : <></>}>

                                                <Form.Control
                                                    type="number"
                                                    value={packed.packedQuantity}
                                                    onChange={(e) => handleUpdatePackedSku(isEditable, setProducerOrder, packed.packedSkuId, 'packedQuantity', e.target.value, debouncedSave)}
                                                    className="packed-input"
                                                    disabled={!isEditable} // Lock volume input if isEditable is false
                                                />

                                            </OverlayTrigger>
                                        </div>

                                        <div className="grid-item"><strong>Packing Notes:</strong></div>
                                        <div className="grid-item">
                                            <OverlayTrigger placement="top" overlay={!isEditable ? renderTooltip : <></>}>

                                                <Form.Control
                                                    type="text"
                                                    value={packed.packingNotes}
                                                    onChange={(e) => handleUpdatePackedSku(producerOrder, setProducerOrder, packed.packedSkuId, 'packingNotes', e.target.value, debouncedSave)}
                                                    className="packed-input"
                                                    disabled={!isEditable} // Lock packing notes input if isEditable is false
                                                />

                                            </OverlayTrigger>
                                        </div>

                                    </div>
                                </div>
                                <OverlayTrigger placement="top" overlay={!isEditable ? renderTooltip : <></>}>
                                    <span className="d-inline-block">
                                        <Button
                                            variant="danger"
                                            size="sm"
                                            className="remove-button"
                                            onClick={() => handleRemovePackedSku(producerOrder, setProducerOrder, packed.packedSkuId, debouncedSave)}
                                            disabled={!isEditable} // Disable if isEditable is false
                                        >
                                            [-]
                                        </Button>
                                    </span>
                                </OverlayTrigger>
                            </div>
                        ))
                    ) : (
                        <div className="grid-item">No packed SKUs</div>
                    )}
                </div>
            ))}
            {/* Auto-Save Status */}
            {isEditable && (
    <div className="save-status mt-3">
        {saveStatus === "Saving..." && <Spinner animation="border" size="sm" />}
        <span className="ml-2">{saveStatus}</span>
    </div>
)}

        </Container>
    );
};

export default ProducerOrderDetails;
