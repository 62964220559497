import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import './InvoiceModal.css'; // Ensure you have appropriate styles for the invoice layout

const InvoiceModal = ({ show, handleClose, order }) => {
  const { finalOrderSummary } = order;
  const { deliveryAddress, orderDetails } = order;
  const { buyerContactName, buyerTradingName, buyerCompanyName, buyerAbn } = orderDetails;

  if (!finalOrderSummary) return null;
  //console.log(finalOrderSummary);

  // Extract necessary data from finalOrderSummary

  const { cartItems, orderTotal, totalItemCost, shipping, platform } = finalOrderSummary;
  const shippingCost = shipping ? shipping.shippingCost : 0;
  const gstOnShipping = shipping ? shipping.gstOnShipping : 0;
  const platformFee = platform ? platform.platformFee : 0;
  const gstOnPlatformFee = platform ? platform.gstOnPlatformFee : 0;

  return (
    <Modal show={show} onHide={handleClose} size="lg" dialogClassName="invoice-modal">
      <Modal.Header closeButton>
        <Modal.Title>Tax Invoice</Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <div className="invoice-container">
          <div className="invoice-title">TAX INVOICE</div>
          {/* Header section with company info */}
          <div className="invoice-header">



            <div className="company-info" style={{ textTransform: 'uppercase' }}>
            <strong>ATTN: {buyerContactName}</strong><br />
              <strong>{buyerCompanyName}</strong><br />
              T/A {buyerTradingName}<br />
              {deliveryAddress.addressLine1}<br />
              {deliveryAddress.addressLine2 && (
                <>
                  {deliveryAddress.addressLine2}<br />
                </>
              )}
              {deliveryAddress.city} {deliveryAddress.region} {deliveryAddress.postcode}<br />
              {deliveryAddress.country}<br />
              ABN: {buyerAbn}
            </div>

            <div className="invoice-meta">
              <div><strong>Invoice Date</strong><br />[Invoice Date]</div>
              <div><strong>Invoice Number</strong><br />[Invoice Number]</div>
              <div><strong>ABN</strong><br />35 667 409 181</div>


            </div>

            <div className="company-info">
              HARVEST STACK AUSTRALIA PTY LTD<br />
              28 ALEXANDRA STREET<br />
              ST KILDA EAST VIC 3183<br />
              AUSTRALIA<br />
              
            </div>


          </div>

          {/* Line items section */}

          {cartItems && cartItems.map((sellerGroup, index) => (
            <React.Fragment key={index}>
              <strong class="seller-name">Invoiced on behalf of {sellerGroup.sellerName}</strong>
              <table className="invoice-table">
                <thead>
                  <tr>
                  <th style={{ textAlign: 'left' }}>Description</th>
                    <th style={{ textAlign: 'left' }}>Category</th>
                    <th style={{ textAlign: 'left' }}>Cut</th>
                    <th style={{ textAlign: 'left' }}>Unit Size</th>
                    <th style={{ textAlign: 'left' }}>Pack Type</th>
                    <th style={{ textAlign: 'left' }}>Process</th>
                    <th style={{ textAlign: 'left' }}>Grade</th>
                    <th>Quantity</th>
                    <th>Unit Price</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {sellerGroup.items.map((item, idx) => (
                    <tr key={idx}>
                      <td style={{ textAlign: 'left' }}>{item.skuName}</td>
                      <td style={{ textAlign: 'left' }}>{item.category}</td>
                      <td style={{ textAlign: 'left' }}>{item.cut}</td>
                      <td style={{ textAlign: 'left' }}>{item.unitSizeName} {item.unitSizeDetail} {item.productSizeMeasure}</td>
                      <td style={{ textAlign: 'left' }}>{item.packType}</td>
                      <td style={{ textAlign: 'left' }}>{item.process}</td>
                      <td style={{ textAlign: 'left' }}>{item.grade}</td>
                      <td>{item.quantity}</td>
                      <td>${item.price.toFixed(2)}</td>
                      <td>${item.total.toFixed(2)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </React.Fragment>
          ))}


          {/* Totals and payment details */}
          <div className="invoice-summary">
            <table className="totals">
              <tbody>
                <tr>
                  <td style={{ paddingRight: 100 + 'px' }}>Total Item Cost:</td>
                  <td>${totalItemCost.toFixed(2)}</td>
                </tr>
                <tr>
                  <td>Shipping Cost:</td>
                  <td>${shippingCost.toFixed(2)}</td>
                </tr>
                <tr>
                  <td>GST on Shipping:</td>
                  <td>${gstOnShipping.toFixed(2)}</td>
                </tr>
                <tr>
                  <td>Platform Fee:</td>
                  <td>${platformFee.toFixed(2)}</td>
                </tr>
                <tr>
                  <td>GST on Platform Fee:</td>
                  <td>${gstOnPlatformFee.toFixed(2)}</td>
                </tr>
                <tr>
                  <td><strong>Order Total:</strong></td>
                  <td><strong>${orderTotal.toFixed(2)}</strong></td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* Payment advice section */}
          <div className="payment-advice">
            <div className="advice-title">PAYMENT ADVICE</div>
            <table className="payment-details">
              <tbody>
                <tr>
                  <td style={{ paddingRight: 100 + 'px' }}>Due Date:</td>
                  <td>[Due Date]</td>
                </tr>
                <tr>
                  <td>Account number:</td>
                  <td>7674 7254</td>
                </tr>
                <tr>
                  <td>BSB:</td>
                  <td>062 692</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal >
  );
};

export default InvoiceModal;
