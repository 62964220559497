import React, { useEffect, useState, useCallback } from 'react';
import { Container, Table, Spinner, Button, Form, Row, Col, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { getOrders } from '../../services/orderService';
import { getAllCompanies } from '../../services/buyerService';
import { FaPlus, FaMinus } from 'react-icons/fa';
import OrderDetailsTable from '../../components/OrderDetailsTable';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../../styles.css';

const Orders = () => {
    const [filteredOrders, setFilteredOrders] = useState([]);
    const [buyers, setBuyers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null); // ✅ State to track errors
    const [expandedRows, setExpandedRows] = useState([]);
    const [filters, setFilters] = useState({
        fromDate: null,
        toDate: null,
        orderStatus: '',
        companyId: '',
    });

    const navigate = useNavigate();

    // Memoize fetchOrders to prevent recreation on every render
    const fetchOrders = useCallback(async () => {
        setLoading(true);
        try {
            const queryParams = {
                startDate: filters.fromDate ? filters.fromDate.toISOString() : null,
                endDate: filters.toDate ? filters.toDate.toISOString() : null,
                orderStatus: filters.orderStatus || null,
                companyId: filters.companyId || null,
            };
            setError(null)
            const ordersData = await getOrders(queryParams);
            setFilteredOrders(ordersData.orders);
        } catch (err) {
            console.error('🚨 Error fetching orders:', err);
            setError(err?.message || 'Failed to fetch orders.');
        } finally {
            setLoading(false);
        }
    }, [filters]);

    useEffect(() => {
        fetchOrders();
    }, [fetchOrders]); // No more missing dependency warning

    useEffect(() => {
        const fetchBuyers = async () => {
            try {
                const buyersData = await getAllCompanies();
                setBuyers(buyersData.companies || []);
            } catch (err) {
                console.error('🚨 Error fetching buyers:', err);
                setError(err?.message || 'Failed to fetch buyers.');
            }
        };

        fetchBuyers();
    }, []);

    const handleRowClick = (orderId) => {
        navigate(`/order-details?orderId=${orderId}`);
    };

    const toggleRowExpansion = (orderId) => {
        setExpandedRows((prevExpandedRows) =>
            prevExpandedRows.includes(orderId)
                ? prevExpandedRows.filter((id) => id !== orderId)
                : [...prevExpandedRows, orderId]
        );
    };

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters((prev) => ({ ...prev, [name]: value }));
    };

    const handleClearFilters = () => {
        setFilters({ fromDate: null, toDate: null, orderStatus: '', companyId: '' });
    };

    const handleExportCSV = () => {
        const csvRows = [
            ['Order ID', 'Buyer Trading Name', 'Order Total', 'Order Status'], // Header row
            ...filteredOrders.map((order) => [
                order.orderId,
                order.orderDetails?.buyerTradingName || '',
                `$${order.orderSummary.orderTotal.toFixed(2)}`,
                order.orderStatus,
            ]),
        ];

        const csvContent = csvRows
            .map((row) => row.map((field) => `"${field}"`).join(',')) // Escape fields with quotes
            .join('\n'); // Join rows with newlines

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `orders_${new Date().toISOString().slice(0, 10)}.csv`;
        link.click();
    };

    const columns = [
        { key: 'orderId', label: 'Order ID' },
        { key: 'buyerContactName', label: 'Buyer Contact Name' },
        { key: 'buyerTradingName', label: 'Buyer Trading Name' },
        { key: 'buyerCompanyName', label: 'Buyer Company Name' },
        { key: 'orderTotal', label: 'Order Total' },
        { key: 'orderStatus', label: 'Order Status' },
        { key: 'createdAt', label: 'Created At' },
        { key: 'updatedAt', label: 'Updated At' },
    ];

    if (loading) {
        return (
            <Container className="content d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </Container>
        );
    }

    return (
        <Container className="content">

            <h1>Orders</h1>

            <Row className="mb-3 g-3">
                <Col xs={12} sm={6} md={2}>
                    <Form.Group>
                        <Form.Label className="small">From Date</Form.Label>
                        <DatePicker
                            selected={filters.fromDate}
                            onChange={(date) => setFilters((prev) => ({ ...prev, fromDate: date }))}
                            className="form-control form-control-sm"
                            dateFormat="yyyy-MM-dd"
                            placeholderText="Select date"
                            isClearable
                            maxDate={new Date()} // Prevent future dates
                            selectsStart
                            startDate={filters.fromDate}
                            endDate={filters.toDate}
                        />
                    </Form.Group>
                </Col>

                <Col xs={12} sm={6} md={2}>
                    <Form.Group>
                        <Form.Label className="small">To Date</Form.Label>
                        <DatePicker
                            selected={filters.toDate}
                            onChange={(date) => setFilters((prev) => ({ ...prev, toDate: date }))}
                            className="form-control form-control-sm"
                            dateFormat="yyyy-MM-dd"
                            placeholderText="Select date"
                            isClearable
                            minDate={filters.fromDate} // Prevent dates before From Date
                            maxDate={new Date()} // Prevent future dates
                            selectsEnd
                            startDate={filters.fromDate}
                            endDate={filters.toDate}
                            disabled={!filters.fromDate} // Disable To Date picker if From Date is not selected
                        />
                    </Form.Group>
                </Col>



                <Col xs={12} sm={6} md={2}>
                    <Form.Group>
                        <Form.Label className="small">Order Status</Form.Label>
                        <Form.Select
                            name="orderStatus"
                            value={filters.orderStatus}
                            onChange={handleFilterChange}
                            className="form-select form-select-sm"
                        >
                            <option value="">All</option>
                            <option value="CHECKOUT">CHECKOUT</option>
                            <option value="PROCESSING">PROCESSING</option>
                            <option value="COMPLETED">COMPLETED</option>
                            <option value="CANCELLED">CANCELLED</option>
                        </Form.Select>
                    </Form.Group>
                </Col>

                <Col xs={12} sm={6} md={3}>
                    <Form.Group>
                        <Form.Label className="small">Buyer Trading Name</Form.Label>
                        <Form.Select
                            name="companyId"
                            value={filters.companyId}
                            onChange={handleFilterChange}
                            className="form-select form-select-sm"
                        >
                            <option value="">All</option>
                            {buyers.map((buyer) => (
                                <option key={buyer.companyId} value={buyer.companyId}>
                                    {buyer.tradingName}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                </Col>

                <Col xs={12} sm={6} md={3} className="d-flex justify-content-end align-items-end">
                    <Button
                        variant="primary"
                        onClick={handleExportCSV}
                        className="btn-sm me-2"
                        disabled={filteredOrders.length === 0} // Disable if no results
                    >
                        Export
                    </Button>

                    <Button
                        variant="danger"
                        onClick={handleClearFilters}
                        className="btn-sm back-button"
                    >
                        Clear filter
                    </Button>

                </Col>
            </Row>

            {/* Display error banner if there's an error */}
            {error && (
                <Alert variant="danger" className="mt-3">
                    <strong>Error:</strong> {error}
                </Alert>
            )
            }
        {!error && (
            <>
            <Table striped bordered hover className="table-responsive" style={{ fontSize: '12px', margin: '0px', padding: '0px' }}>
                <thead>
                    <tr>
                        <th style={{ padding: '5px' }}></th>
                        {columns.map((col) => (
                            <th key={col.key} style={{ whiteSpace: 'nowrap', padding: '5px' }}>{col.label}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {filteredOrders.length > 0 ? (
                        filteredOrders.map((order) => (
                            <React.Fragment key={order.orderId}>
                                <tr style={{ cursor: 'pointer' }} onClick={() => handleRowClick(order.orderId)}>
                                    <td style={{ whiteSpace: 'nowrap', padding: '0px' }} onClick={(e) => e.stopPropagation()}>
                                        <Button variant="link" size="sm"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                toggleRowExpansion(order.orderId);
                                            }}
                                            style={{ paddingTop: 0, paddingBottom: 0, margin: 0 }}
                                        >
                                            {expandedRows.includes(order.orderId) ? <FaMinus /> : <FaPlus />}
                                        </Button>
                                    </td>
                                    <td>{order.orderId}</td>
                                    <td>{order.orderDetails?.buyerContactName}</td>
                                    <td>{order.orderDetails.buyerTradingName}</td>
                                    <td>{order.orderDetails.buyerCompanyName}</td>
                                    <td>${order.orderSummary.orderTotal.toFixed(2)}</td>
                                    <td>{order.orderStatus}</td>
                                    <td>{new Date(order.createdAt).toLocaleString()}</td>
                                    <td>{new Date(order.updatedAt).toLocaleString()}</td>
                                </tr>
                                {expandedRows.includes(order.orderId) && (
                                    <tr>
                                        <td colSpan="9" style={{ padding: '0px' }}>
                                            <OrderDetailsTable order={order} />
                                        </td>
                                    </tr>
                                )}
                            </React.Fragment>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={columns.length + 1} className="text-center">
                                No results
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
</>
)}
        </Container>
    );
};

export default Orders;
