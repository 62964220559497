import React, { useState } from 'react';
import { Container, Card, Button, Form, Alert, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../firebaseConfig';
import { FaArrowLeft, FaSave } from 'react-icons/fa';
import Select from 'react-select'; // Importing react-select

const CreateBuyers = () => {
  const [newCompany, setNewCompany] = useState({
    companyName: '',
    tradingName: '',
    ABN: '',
    deliveryAddress: {
      addressLine1: '',
      city: '',
      region: '',
      postcode: '',
      country: 'Australia',
    },
    billingAddress: {
      addressLine1: '',
      city: '',
      region: '',
      postcode: '',
      country: 'Australia',
    },
    companyHomepage: '',
    companyEmail: '',
    companyPhone: '',
  });

  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [saveError, setSaveError] = useState(null);
  const [validationErrors, setValidationErrors] = useState({});
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const [section, key] = name.split('.');

    if (key) {
      setNewCompany((prevState) => ({
        ...prevState,
        [section]: {
          ...prevState[section],
          [key]: value,
        },
      }));
    } else {
      setNewCompany((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSelectChange = (selectedOption, name) => {
    const [section, key] = name.split('.');

    if (key) {
      setNewCompany((prevState) => ({
        ...prevState,
        [section]: {
          ...prevState[section],
          [key]: selectedOption ? selectedOption.value : '',
        },
      }));
    }
  };

  const validateFields = () => {
    const errors = {};

    // Check if all fields are filled
    if (!newCompany.companyName) errors.companyName = 'Company Name is required';
    if (!newCompany.tradingName) errors.tradingName = 'Trading Name is required';
    if (!newCompany.ABN) errors.ABN = 'ABN is required';
    if (!newCompany.deliveryAddress.addressLine1) errors.deliveryAddressLine1 = 'Delivery Address Line 1 is required';
    if (!newCompany.deliveryAddress.city) errors.deliveryCity = 'Delivery City is required';
    if (!newCompany.deliveryAddress.region) errors.deliveryRegion = 'Delivery State is required';
    if (!newCompany.deliveryAddress.postcode) errors.deliveryPostcode = 'Delivery Postcode is required';
    if (!newCompany.billingAddress.addressLine1) errors.billingAddressLine1 = 'Billing Address Line 1 is required';
    if (!newCompany.billingAddress.city) errors.billingCity = 'Billing City is required';
    if (!newCompany.billingAddress.region) errors.billingRegion = 'Billing State is required';
    if (!newCompany.billingAddress.postcode) errors.billingPostcode = 'Billing Postcode is required';
    if (!newCompany.companyHomepage) errors.companyHomepage = 'Company Homepage is required';
    if (!newCompany.companyEmail) errors.companyEmail = 'Company Email is required';
    if (!newCompany.companyPhone) errors.companyPhone = 'Company Phone is required';

    setValidationErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const handleSave = async () => {
    if (!validateFields()) return;

    setIsSaving(true);
    setIsSaved(false);
    setSaveError(null);

    try {
      const createCompany = httpsCallable(functions, 'createCompany');
      const response = await createCompany({
        ...newCompany
      });

      if (response.data.success) {
        setIsSaved(true);
        setNewCompany({
          companyName: '',
          tradingName: '',
          ABN: '',
          deliveryAddress: {
            addressLine1: '',
            city: '',
            region: '',
            postcode: '',
            country: 'Australia',
          },
          billingAddress: {
            addressLine1: '',
            city: '',
            region: '',
            postcode: '',
            country: 'Australia',
          },
          companyHomepage: '',
          companyEmail: '',
          companyPhone: '',
        });
      } else {
        throw new Error(response.data.error || 'Failed to create company');
      }
    } catch (error) {
      console.error('Error creating company:', error);
      setSaveError(error.message + ': ' + error.details);
    } finally {
      setIsSaving(false);
    }
  };

  const handleBack = () => {
    navigate('/buyers');
  };

  const stateOptions = [
    { value: 'NSW', label: 'New South Wales' },
    { value: 'VIC', label: 'Victoria' },
    { value: 'QLD', label: 'Queensland' },
    { value: 'SA', label: 'South Australia' },
    { value: 'WA', label: 'Western Australia' },
    { value: 'TAS', label: 'Tasmania' },
    { value: 'ACT', label: 'Australian Capital Territory' },
    { value: 'NT', label: 'Northern Territory' },
  ];

  return (
    <Container className="content">
      <h1>Create New Buyer</h1>
      <div className="d-flex justify-content-between mb-3">
        <Button variant="secondary" onClick={handleBack} disabled={isSaving} className="btn-sm back-button">
          <FaArrowLeft className="me-2" style={{ fontSize: "0.875rem" }} />
          Back
        </Button>
        <Button 
          size="sm"
          variant="primary" 
          onClick={handleSave} 
          disabled={isSaving}
        >
          {isSaving ? (
            <span className="spinner-border spinner-border-sm"></span>
          ) : (
            <>
              <FaSave className="me-2 mb-1" />
              Save
            </>
          )}
        </Button>
      </div>
      {isSaved && <Alert variant="success">Company has been created successfully</Alert>}
      {saveError && <Alert variant="danger">{saveError}</Alert>}
      <Card className="mb-3">
        <Card.Header>Company Information</Card.Header>
        <Card.Body>
          <Form>
            <Row>
              <Col md={6} className="mb-3">
                <Form.Group>
                  <Form.Label>Company Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="companyName"
                    value={newCompany.companyName}
                    onChange={handleInputChange}
                    isInvalid={!!validationErrors.companyName}
                    disabled={isSaving}
                  />
                  <Form.Control.Feedback type="invalid">
                    {validationErrors.companyName}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group>
                  <Form.Label>Trading Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="tradingName"
                    value={newCompany.tradingName}
                    onChange={handleInputChange}
                    isInvalid={!!validationErrors.tradingName}
                    disabled={isSaving}
                  />
                  <Form.Control.Feedback type="invalid">
                    {validationErrors.tradingName}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group>
                  <Form.Label>ABN</Form.Label>
                  <Form.Control
                    type="text"
                    name="ABN"
                    value={newCompany.ABN}
                    onChange={handleInputChange}
                    isInvalid={!!validationErrors.ABN}
                    disabled={isSaving}
                  />
                  <Form.Control.Feedback type="invalid">
                    {validationErrors.ABN}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group>
                  <Form.Label>Company Homepage</Form.Label>
                  <Form.Control
                    type="text"
                    name="companyHomepage"
                    value={newCompany.companyHomepage}
                    onChange={handleInputChange}
                    isInvalid={!!validationErrors.companyHomepage}
                    disabled={isSaving}
                  />
                  <Form.Control.Feedback type="invalid">
                    {validationErrors.companyHomepage}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group>
                  <Form.Label>Company Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="companyEmail"
                    value={newCompany.companyEmail}
                    onChange={handleInputChange}
                    isInvalid={!!validationErrors.companyEmail}
                    disabled={isSaving}
                  />
                  <Form.Control.Feedback type="invalid">
                    {validationErrors.companyEmail}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group>
                  <Form.Label>Company Phone</Form.Label>
                  <Form.Control
                    type="text"
                    name="companyPhone"
                    value={newCompany.companyPhone}
                    onChange={handleInputChange}
                    isInvalid={!!validationErrors.companyPhone}
                    disabled={isSaving}
                  />
                  <Form.Control.Feedback type="invalid">
                    {validationErrors.companyPhone}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
      <Card className="mb-3">
        <Card.Header>Delivery Address</Card.Header>
        <Card.Body>
          <Form>
            <Row>
              <Col md={6} className="mb-3">
                <Form.Group>
                  <Form.Label>Address Line 1</Form.Label>
                  <Form.Control
                    type="text"
                    name="deliveryAddress.addressLine1"
                    value={newCompany.deliveryAddress.addressLine1}
                    onChange={handleInputChange}
                    isInvalid={!!validationErrors.deliveryAddressLine1}
                    disabled={isSaving}
                  />
                  <Form.Control.Feedback type="invalid">
                    {validationErrors.deliveryAddressLine1}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group>
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    type="text"
                    name="deliveryAddress.city"
                    value={newCompany.deliveryAddress.city}
                    onChange={handleInputChange}
                    isInvalid={!!validationErrors.deliveryCity}
                    disabled={isSaving}
                  />
                  <Form.Control.Feedback type="invalid">
                    {validationErrors.deliveryCity}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group>
                  <Form.Label>State</Form.Label>
                  <Select
                    name="deliveryAddress.region"
                    options={stateOptions}
                    value={stateOptions.find(option => option.value === newCompany.deliveryAddress.region)}
                    onChange={(selectedOption) => handleSelectChange(selectedOption, 'deliveryAddress.region')}
                    isInvalid={!!validationErrors.deliveryRegion}
                    isDisabled={isSaving}
                  />
                  {validationErrors.deliveryRegion && (
                    <div className="invalid-feedback d-block">{validationErrors.deliveryRegion}</div>
                  )}
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group>
                  <Form.Label>Postcode</Form.Label>
                  <Form.Control
                    type="text"
                    name="deliveryAddress.postcode"
                    value={newCompany.deliveryAddress.postcode}
                    onChange={handleInputChange}
                    isInvalid={!!validationErrors.deliveryPostcode}
                    disabled={isSaving}
                  />
                  <Form.Control.Feedback type="invalid">
                    {validationErrors.deliveryPostcode}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group>
                  <Form.Label>Country</Form.Label>
                  <Form.Control
                    type="text"
                    name="deliveryAddress.country"
                    value="Australia"
                    readOnly
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
      <Card className="mb-3">
        <Card.Header>Billing Address</Card.Header>
        <Card.Body>
          <Form>
            <Row>
              <Col md={6} className="mb-3">
                <Form.Group>
                  <Form.Label>Address Line 1</Form.Label>
                  <Form.Control
                    type="text"
                    name="billingAddress.addressLine1"
                    value={newCompany.billingAddress.addressLine1}
                    onChange={handleInputChange}
                    isInvalid={!!validationErrors.billingAddressLine1}
                    disabled={isSaving}
                  />
                  <Form.Control.Feedback type="invalid">
                    {validationErrors.billingAddressLine1}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group>
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    type="text"
                    name="billingAddress.city"
                    value={newCompany.billingAddress.city}
                    onChange={handleInputChange}
                    isInvalid={!!validationErrors.billingCity}
                    disabled={isSaving}
                  />
                  <Form.Control.Feedback type="invalid">
                    {validationErrors.billingCity}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group>
                  <Form.Label>State</Form.Label>
                  <Select
                    name="billingAddress.region"
                    options={stateOptions}
                    value={stateOptions.find(option => option.value === newCompany.billingAddress.region)}
                    onChange={(selectedOption) => handleSelectChange(selectedOption, 'billingAddress.region')}
                    isInvalid={!!validationErrors.billingRegion}
                    isDisabled={isSaving}
                  />
                  {validationErrors.billingRegion && (
                    <div className="invalid-feedback d-block">{validationErrors.billingRegion}</div>
                  )}
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group>
                  <Form.Label>Postcode</Form.Label>
                  <Form.Control
                    type="text"
                    name="billingAddress.postcode"
                    value={newCompany.billingAddress.postcode}
                    onChange={handleInputChange}
                    isInvalid={!!validationErrors.billingPostcode}
                    disabled={isSaving}
                  />
                  <Form.Control.Feedback type="invalid">
                    {validationErrors.billingPostcode}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group>
                  <Form.Label>Country</Form.Label>
                  <Form.Control
                    type="text"
                    name="billingAddress.country"
                    value="Australia"
                    readOnly
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default CreateBuyers;
