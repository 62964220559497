import React, { useState, useEffect, useMemo } from 'react';
import { Container, Spinner, Alert, Button } from 'react-bootstrap';
import { FaArrowLeft } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import debounce from 'lodash/debounce';

import { getSkuDetails, updateSku } from '../../services/skuService';
import { getSellers } from '../../services/sellerService';
import { getSpecies } from '../../services/speciesService';

// Import your existing renderField function
import { renderField } from '../../utils/renderField'; 

// Define which fields to display and how
const FIELD_DEFINITIONS = [
  { label: 'SKU Name', name: 'skuName', type: 'text' },
  { label: 'Xero SKU', name: 'xeroSku', type: 'text' },
  { label: 'Species', name: 'speciesId', type: 'species' },
  { label: 'Seller', name: 'sellerId', type: 'seller' },
  { label: 'Show In App', name: 'showInApp', type: 'toggle' },
  { label: 'Availability Status', name: 'availabilityStatus', type: 'picklist', picklistKey: 'availabilityStatus' },
  { label: 'Price', name: 'price', type: 'number' },
  { label: 'UOM', name: 'uom', type: 'picklist', picklistKey: 'uom' },
  { label: 'Category', name: 'category', type: 'picklist', picklistKey: 'category' },
  { label: 'Sub Category', name: 'subCategory', type: 'picklist', picklistKey: 'subCategory' },
  { label: 'Cut', name: 'cut', type: 'picklist', picklistKey: 'cut' },
  { label: 'Unit Size Name', name: 'unitSizeName', type: 'picklist', picklistKey: 'unitSizeName' },
  { label: 'Pack Format', name: 'packFormat', type: 'picklist', picklistKey: 'packFormat' },
  { label: 'Pack Type', name: 'packType', type: 'picklist', picklistKey: 'packType' },
  { label: 'Product Image', name: 'productImage', type: 'text' },
  { label: 'Unit Size Detail', name: 'unitSizeDetail', type: 'text' },
  { label: 'Product Size Measure', name: 'productSizeMeasure', type: 'picklist', picklistKey: 'productSizeMeasure' },
  { label: 'Process', name: 'process', type: 'picklist', picklistKey: 'process' },
  { label: 'MOQ', name: 'moq', type: 'text' },
  { label: 'Grade', name: 'grade', type: 'picklist', picklistKey: 'grade' },
];

const ShowInAppEditor = () => {
  const { skuId } = useParams();
  const navigate = useNavigate();

  const [sku, setSku] = useState(null);
  const [sellers, setSellers] = useState([]);
  const [species, setSpecies] = useState([]);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [saveStatus, setSaveStatus] = useState("Auto saved");

  // Fetch SKU + supporting data on mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedSku = await getSkuDetails(skuId);
        if (!fetchedSku) throw new Error("SKU not found");
        setSku(fetchedSku);

        const sellerList = await getSellers();
        setSellers(sellerList);

        const speciesRes = await getSpecies();
        setSpecies(speciesRes?.species || []);

      } catch (err) {
        console.error("Error fetching data:", err);
        setError("Failed to load data.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [skuId]);

  // Debounced patch function
  const debouncedSave = useMemo(
    () =>
      debounce(async (changedFields) => {
        if (!Object.keys(changedFields).length) return;
        setSaveStatus("Saving...");
        try {
          await updateSku(skuId, changedFields);
          setSaveStatus("Auto saved");
        } catch (err) {
          console.error("Error auto-saving patch:", err);
          setSaveStatus("Error saving");
        }
      }, 500),
    [skuId]
  );

  // Our custom setFormData wrapper for minimal patch
  const setFormData = (updater) => {
    setSku((prev) => {
      if (!prev) return prev;

      const newState =
        typeof updater === 'function'
          ? updater(prev)
          : { ...prev, ...updater };

      const changedFields = {};
      for (const key of Object.keys(newState)) {
        if (newState[key] !== prev[key]) {
          changedFields[key] = newState[key];
        }
      }

      if (Object.keys(changedFields).length > 0) {
        debouncedSave(changedFields);
      }

      return newState;
    });
  };

  // No field-level errors in this example
  const errors = {};

  // Convert possible Firestore/ISO timestamps
  // Adjust logic depending on how your timestamps are stored
  const formatTimestamp = (ts) => {
    if (!ts) return "N/A";
    // If ts is a Firestore Timestamp, do ts.toDate().
    // If it's an ISO string, do new Date(ts).
    try {
      const date = new Date(ts);
      return date.toLocaleString();
    } catch {
      return ts.toString();
    }
  };

  if (loading) {
    return (
      <Container className="mt-4 text-center">
        <Spinner animation="border">
          <span className="visually-hidden">Loading SKU...</span>
        </Spinner>
      </Container>
    );
  }

  if (error) {
    return (
      <Container className="mt-4">
        <Alert variant="danger">{error}</Alert>
      </Container>
    );
  }

  if (!sku) {
    return (
      <Container className="mt-4">
        <Alert variant="warning">No SKU found.</Alert>
      </Container>
    );
  }

  // Format created/updated timestamps
  const createdAtStr = formatTimestamp(sku.createdAt);
  const updatedAtStr = formatTimestamp(sku.updatedAt);

  return (
    <Container className="mt-4">
      <h1>SKU Details</h1>
      <Button
        variant="secondary"
        onClick={() => navigate("/skusv3")}
        className="btn-sm back-button mb-2"
      >
        <FaArrowLeft className="me-2" style={{ fontSize: "0.875rem" }} />
        Back
      </Button>

      {/* Print created date, updated date, createdBy, updatedBy */}
      <div className="text-muted mb-3">
        <p><strong>Created At:</strong> {createdAtStr}</p>
        <p><strong>Updated At:</strong> {updatedAtStr}</p>
        <p><strong>Created By:</strong> {sku.createdBy || "N/A"}</p>
        <p><strong>Updated By:</strong> {sku.updatedBy || "N/A"}</p>
      </div>



      <div className="mb-2">
        {saveStatus === "Saving..." && <Spinner animation="border" size="sm" />}
        <span className="ms-2">{saveStatus}</span>
      </div>

      {/* Render each field via renderField */}
      {FIELD_DEFINITIONS.map((fieldDef) =>
        renderField(fieldDef, sku, setFormData, sellers, [], species, errors)
      )}
    </Container>
  );
};

export default ShowInAppEditor;
