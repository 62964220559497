import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebaseConfig';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Skus from './pages/skus/Skus';
import SkusV3 from './pages/skus/SkusV3';
import SkuDetails from './pages/skus/SkuDetails';
import SkuDetailsV3 from './pages/skus/SkuDetailsV3';
import Orders from './pages/orders/Orders';
import OrderDetails from './pages/orders/OrderDetails';
import ProducerOrderDetails from './pages/orders/ProducerOrderDetails';
import Sellers from './pages/sellers/Sellers';
import SellerDetails from './pages/sellers/SellerDetails';
import Species from './pages/species/Species';
import SpeciesDetails from './pages/species/SpeciesDetails';
import Regions from './pages/regions/Regions';
import RegionDetails from './pages/regions/RegionDetails';
import Companies from './pages/buyers/Buyers';
import CompanyDetails from './pages/buyers/BuyerDetails';
import Profile from './pages/users/Profile';
import Users from './pages/users/Users';
import UserDetails from './pages/users/UserDetails';
import ConsoleAdmin from './pages/ConsoleAdmin';
import SendOTP from './pages/SendOtp';
import CreateUser from './pages/users/CreateUser';
import CreateBuyer from './pages/buyers/CreateBuyer';

import Organisations from './pages/organisations/Organisations';
import OrganisationDetails from './pages/organisations/OrganisationDetails';

import Navbar from './components/Navbar';
import './styles.css';
import { setDarkMode, getStoredTheme } from './themeUtils';
import { TbFishBone } from 'react-icons/tb'; // Import the FishBone icon

const App = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [theme, setThemeState] = useState(getStoredTheme());

  // Set document title based on URL
  useEffect(() => {
    const hostname = window.location.hostname;

    if (hostname.includes('localhost')) {
      document.title = 'Console Local';
    } else if (hostname.includes('console-dev')) {
      document.title = 'Console Dev';
    } else if (hostname.includes('console-test')) {
      document.title = 'Console Test';
    } else if (hostname.includes('console.harveststack.co')) {
      document.title = 'Console Prod';
    }
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const token = await user.getIdTokenResult();
        const roles = token.claims.role || [];

        if (!roles.includes('hsAdmin')) {
          setUser(null);
          auth.signOut();
        } else {
          setUser(user);
        }
      } else {
        setUser(null);
      }
      setLoading(false); // Set loading to false once auth state is determined
    });

    return unsubscribe;
  }, []);

  useEffect(() => {
    setDarkMode(theme);
  }, [theme]);

  if (loading) {
    return <div></div>; // Display a loading indicator while checking auth state
  }

  return (
    <Router>
      {user && <Navbar theme={theme} setTheme={setThemeState} />}
      <div className="content">
        <Routes>
          <Route path="/send-otp" element={<SendOTP />} />
          <Route path="/login" element={!user ? <Login /> : <Navigate to="/" />} />
          <Route path="/" element={user ? <Dashboard theme={theme} /> : <Navigate to="/login" />} />
          <Route path="/skus" element={user ? <Skus /> : <Navigate to="/login" />} />
          <Route path="/skusv3" element={user ? <SkusV3 /> : <Navigate to="/login" />} />
          <Route path="/skus/:skuId" element={user ? <SkuDetails /> : <Navigate to="/login" />} />
          <Route path="/skusv3/:skuId" element={user ? <SkuDetailsV3 /> : <Navigate to="/login" />} />
          <Route path="/orders" element={user ? <Orders /> : <Navigate to="/login" />} />
          <Route path="/order-details" element={user ? <OrderDetails /> : <Navigate to="/login" />} />
          <Route path="/producer-order-details" element={user ? <ProducerOrderDetails /> : <Navigate to="/login" />} />
          <Route path="/sellers" element={user ? <Sellers /> : <Navigate to="/login" />} />
          <Route path="/seller-details" element={user ? <SellerDetails /> : <Navigate to="/login" />} />
          <Route path="/species" element={user ? <Species /> : <Navigate to="/login" />} />
          <Route path="/species-details" element={user ? <SpeciesDetails /> : <Navigate to="/login" />} />
          <Route path="/regions" element={user ? <Regions /> : <Navigate to="/login" />} />
          <Route path="/region-details" element={user ? <RegionDetails /> : <Navigate to="/login" />} />
          <Route path="/buyers" element={user ? <Companies /> : <Navigate to="/login" />} />
          <Route path="/buyer-details" element={user ? <CompanyDetails /> : <Navigate to="/login" />} />
          <Route path="/organisations" element={user ? <Organisations /> : <Navigate to="/login" />} />
          <Route path="/organisation-details" element={user ? <OrganisationDetails /> : <Navigate to="/login" />} />
          <Route path="/users" element={user ? <Users /> : <Navigate to="/login" />} />
          <Route path="/user-details" element={user ? <UserDetails /> : <Navigate to="/login" />} />
          <Route path="/create-user" element={user ? <CreateUser /> : <Navigate to="/login" />} /> {/* Add this route */}
          <Route path="/create-buyer" element={user ? <CreateBuyer /> : <Navigate to="/login" />} /> {/* Add this route */}
          <Route path="/console-admin" element={user ? <ConsoleAdmin /> : <Navigate to="/login" />} />
          <Route path="/profile" element={user ? <Profile /> : <Navigate to="/login" />} />
          <Route path="/logging-out" element={<LoggingOut />} />
        </Routes>
      </div>
    </Router>
  );
};

const LoggingOut = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.href = '/login';
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="loading-container">
      <TbFishBone className="loading-icon" />
    </div>
  );
};

export default App;
