// src/services/regionService.js

import { httpsCallable } from "firebase/functions";
import { functions } from "../firebaseConfig";  // Assuming firebaseConfig correctly exports initialized Firebase app

export const getAllRegions = async () => {
  const getAllRegionsCallable = httpsCallable(functions, 'getAllRegions');
  const response = await getAllRegionsCallable();
  return response.data;  // This should return an object like { result: { success: true, regions: [...] } }
};

export const getRegionById = async (regionId) => {
  const getRegionByIdCallable = httpsCallable(functions, 'getRegionById');
  const response = await getRegionByIdCallable({ regionId });
  return response.data.region;
};


export const updateRegion = async (regionId, regionData) => {
  const updateRegionCallable = httpsCallable(functions, 'updateRegion');
  const response = await updateRegionCallable({ regionId, ...regionData });
  return response.data;
};



// NEW ONES

// NEW ONES

export const getRegions = async (scope) => {
  try {
    const getRegions = httpsCallable(functions, 'getRegions');
    const response = await getRegions({ scope }); // Pass the scope parameter
    //console.log('Regions:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching regions:', error);
    throw new Error('Failed to fetch regions');
  }
};