import { functions } from "../firebaseConfig"; 
import { getAuth } from 'firebase/auth';
import { httpsCallable } from "firebase/functions";
import { getBaseUrl } from '../utils/apiConfig';

// ✅ Get user's ID Token for authentication
const getAuthToken = async () => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (!user) throw new Error("User not authenticated");
    return user.getIdToken();
};

// ✅ Fetch API with authentication
const fetchWithAuth = async (endpoint, method = "GET", body = null) => {
    try {
        const idToken = await getAuthToken();
        const apiUrl = `${getBaseUrl()}${endpoint}`;

        const response = await fetch(apiUrl, {
            method,
            headers: {
                'Authorization': `Bearer ${idToken}`,
                'Content-Type': 'application/json'
            },
            body: body ? JSON.stringify(body) : null
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const apiResponse = await response.json();
        return apiResponse.data || null;
    } catch (error) {
        console.error(`🚨 Error in fetchWithAuth (${method} ${endpoint}):`, error);
        throw error;
    }
};

// ✅ Update SKU using Firebase Callable Function
export const updateSkuVXCallable = async (skuId, skuData) => {
    const updateSkuVXCallable = httpsCallable(functions, 'updateSkuVX');
    const response = await updateSkuVXCallable({ skuId, ...skuData });
    return response.data;
};

// ✅ Get all SKUs
export const getSkus = async () => fetchWithAuth("/skus");

// ✅ Get SKU details by ID
export const getSkuDetails = async (skuId) => fetchWithAuth(`/skus/${skuId}`);

// ✅ Create a new SKU
export const createSku = async (skuData) => fetchWithAuth("/skus/", "POST", skuData);

// ✅ Update an existing SKU
export const updateSku = async (skuId, skuData) => fetchWithAuth(`/skus/${skuId}`, "PATCH", skuData);
