import React, { useEffect, useRef } from "react";
import { Navbar as BootstrapNavbar, Container, Nav, Dropdown, Offcanvas } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useNavigate } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";
import { FaUser, FaCog, FaAdjust } from "react-icons/fa";
import { BsFillSunFill, BsFillMoonStarsFill, BsCheckLg } from "react-icons/bs";
import { setDarkMode } from "../themeUtils";
import { Offcanvas as BootstrapOffcanvas } from "bootstrap";
import "./Navbar.css"; // Import custom navbar styles


const Navbar = ({ theme, setTheme }) => {
  const navigate = useNavigate();
  const offcanvasRef = useRef(null);

  useEffect(() => {
    setDarkMode(theme);
  }, [theme]);

  const handleLogout = () => {
    signOut(getAuth()).then(() => {
      navigate("/logging-out");
    });
  };

  const handleThemeChange = (newTheme) => {
    setTheme(newTheme);
  };

  const renderThemeIcon = () => {
    switch (theme) {
      case "dark":
        return <BsFillMoonStarsFill />;
      case "light":
        return <BsFillSunFill />;
      case "auto":
        return <FaAdjust />;
      default:
        return <BsFillSunFill />;
    }
  };

  const logoSrc = theme === "dark" ? "/hs-light-text.png" : "/hs-dark-text.png";

  const handleLinkClick = () => {
    if (offcanvasRef.current) {
      const offcanvasElement = offcanvasRef.current;
      const offcanvasInstance = BootstrapOffcanvas.getInstance(offcanvasElement);
      offcanvasInstance?.hide();
    }
  };

  return (
    <BootstrapNavbar
      bg={theme === "dark" ? "dark" : "light"}
      expand="lg"
      fixed="top"
      variant={theme === "dark" ? "dark" : "light"}
    >
      <Container>
        {/* Navbar Brand */}
        <BootstrapNavbar.Brand>
          <img src={logoSrc} alt="Harvest Stack Console" height="50" className="me-3" />
        </BootstrapNavbar.Brand>

        {/* Toggle Button for Offcanvas Menu */}
        <BootstrapNavbar.Toggle aria-controls="offcanvasNavbar" className="d-lg-none" />

        {/* Offcanvas for Mobile */}
        <BootstrapNavbar.Offcanvas
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
          placement="start"
          className="d-lg-none"
          ref={offcanvasRef}
        >
          <Offcanvas.Header closeButton>
            <img src={logoSrc} alt="Harvest Stack Console" height="50" className="me-3" />
          </Offcanvas.Header>
          <Offcanvas.Body>
            <NavigationLinks onClick={handleLinkClick} />
          </Offcanvas.Body>
        </BootstrapNavbar.Offcanvas>

        {/* Desktop Navigation */}
        <BootstrapNavbar.Collapse id="basic-navbar-nav">
          <NavigationLinks />
          <UserControls
            theme={theme}
            handleThemeChange={handleThemeChange}
            renderThemeIcon={renderThemeIcon}
            handleLogout={handleLogout}
          />
        </BootstrapNavbar.Collapse>
      </Container>
    </BootstrapNavbar>
  );
};

/**
 * ✅ Renders main navigation links
 */
const NavigationLinks = ({ onClick }) => (
  <Nav className="me-auto">
    <LinkContainer to="/" onClick={onClick}>
      <Nav.Link>Dashboard</Nav.Link>
    </LinkContainer>
    <LinkContainer to="/skus" onClick={onClick}>
      <Nav.Link>SKUs</Nav.Link>
    </LinkContainer>
    <LinkContainer to="/skusv3" onClick={onClick}>
      <Nav.Link>SKUs V3</Nav.Link>
    </LinkContainer>
    <LinkContainer to="/orders" onClick={onClick}>
      <Nav.Link>Orders</Nav.Link>
    </LinkContainer>
    <DropdownNav title="Buyers" links={[{ path: "/create-buyer", label: "Create" }, { path: "/buyers", label: "Manage" }]} onClick={onClick}/>
    <LinkContainer to="/sellers" onClick={onClick}>
      <Nav.Link>Sellers</Nav.Link>
    </LinkContainer>
    <LinkContainer to="/species" onClick={onClick}>
      <Nav.Link>Species</Nav.Link>
    </LinkContainer>
    <LinkContainer to="/regions" onClick={onClick}>
      <Nav.Link>Regions</Nav.Link>
    </LinkContainer>
    <DropdownNav title="Users" links={[{ path: "/create-user", label: "Create" }, { path: "/users", label: "Manage" }]} onClick={onClick} />
  </Nav>
);

/**
 * ✅ Renders User Controls (Theme, Profile, Admin)
 */
const UserControls = ({ theme, handleThemeChange, renderThemeIcon, handleLogout }) => (
  <Nav>
    {/* Theme Dropdown */}
    <DropdownWithIcon title={renderThemeIcon()} id="theme-dropdown">
      <DropdownItem icon={<BsFillSunFill />} label="Light" isSelected={theme === "light"} onClick={() => handleThemeChange("light")} />
      <DropdownItem icon={<BsFillMoonStarsFill />} label="Dark" isSelected={theme === "dark"} onClick={() => handleThemeChange("dark")} />
      <DropdownItem icon={<FaAdjust />} label="Auto" isSelected={theme === "auto"} onClick={() => handleThemeChange("auto")} />
    </DropdownWithIcon>

    {/* Settings Dropdown */}
    <DropdownWithIcon title={<FaCog />} id="settings-dropdown">
      <LinkContainer to="/console-admin">
        <Dropdown.Item>Console Admin</Dropdown.Item>
      </LinkContainer>
    </DropdownWithIcon>

    {/* User Dropdown */}
    <DropdownWithIcon title={<FaUser />} id="user-dropdown">
      <LinkContainer to="/profile">
        <Dropdown.Item>Profile</Dropdown.Item>
      </LinkContainer>
      <Dropdown.Item onClick={handleLogout}>Sign out</Dropdown.Item>
    </DropdownWithIcon>
  </Nav>
);

/**
 * ✅ A generic dropdown for navigation items
 */
const DropdownNav = ({ title, links, onClick }) => (
  <Dropdown>
    <Dropdown.Toggle variant="link" className="nav-link">{title}</Dropdown.Toggle>
    <Dropdown.Menu>
      {links.map(({ path, label }) => (
        <LinkContainer to={path} key={path} onClick={onClick}>
          <Dropdown.Item>{label}</Dropdown.Item>
        </LinkContainer>
      ))}
    </Dropdown.Menu>
  </Dropdown>
);

/**
 * ✅ A generic dropdown with an icon
 */
const DropdownWithIcon = ({ title, id, children }) => (
  <Dropdown className="ms-2">
    <Dropdown.Toggle className="no-border" variant="outline-secondary" id={id}>
      {title}
    </Dropdown.Toggle>
    <Dropdown.Menu>{children}</Dropdown.Menu>
  </Dropdown>
);

/**
 * ✅ A reusable dropdown item with an icon and checkmark
 */
const DropdownItem = ({ icon, label, isSelected, onClick }) => (
  <Dropdown.Item onClick={onClick}>
    {icon} <span className="ms-2">{label}</span> {isSelected && <BsCheckLg className="checkmark mt-1 ms-2" />}
  </Dropdown.Item>
);

export default Navbar;
