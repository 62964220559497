import React from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { picklists } from "../validationSchemas/skuSchema"; // Adjust the path if needed

/** A single field block, with label above */
function FieldBlock({ label, children }) {
    return (
        <div className="jira-field-group">
            <small className="jira-field-label">{label}</small>
            {children}
        </div>
    );
}

/** Renders a form field based on the field definition */
export function renderField(field, formData, setFormData, sellers, regions, species, errors) {
    const { label, name, type, picklistKey } = field;
    const hasError = !!errors[name]; // Boolean flag if the field has an error

    // Custom styles for react-select
    const selectStyles = {
        control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: hasError ? "#dc3545" : state.isFocused ? "#dee2e6" : baseStyles.borderColor, // Red for error, Grey for focus
            boxShadow: hasError ? "0 0 0 0.5px #dc3545" : state.isFocused ? "0 0 0 0.5px #dee2e6" : "none",
            "&:hover": {
                borderColor: hasError ? "#dc3545" : "#dee2e6",
            },
        }),
    };

    // Text Input
    if (type === "text") {
        return (
            <FieldBlock key={name} label={label}>
                <Form.Control
                    type="text"
                    name={name}
                    className={hasError ? "border-danger" : ""}
                    value={formData[name] || ""}
                    onChange={(e) =>
                        setFormData((prev) => ({
                            ...prev,
                            [name]: e.target.value,
                        }))
                    }
                />
            </FieldBlock>
        );
    }

    // Region selector
    if (type === "region") {
        const regionOptions = regions.map((r) => ({ value: r.regionId, label: r.regionName }));
        const selected = regionOptions.find((o) => o.value === formData.regionId) || null;
        return (
            <FieldBlock key={name} label={label}>
                <Select
                    className="my-react-select"
                    classNamePrefix="my-select"
                    placeholder={`Select ${label}`}
                    value={selected}
                    options={regionOptions}
                    onChange={(option) =>
                        setFormData((prev) => ({
                            ...prev,
                            regionId: option?.value || "",
                            regionName: option?.label || "",
                        }))
                    }
                    isClearable
                />
            </FieldBlock>
        );
    }

    // Seller selector
    if (type === "seller") {
        const sellerOptions = sellers.map((s) => ({ value: s.sellerId, label: s.sellerName }));
        const selected = sellerOptions.find((o) => o.value === formData.sellerId) || null;

        return (
            <FieldBlock key={name} label={label}>
                <Select
                    styles={selectStyles}
                    className="my-react-select"
                    placeholder={`Select ${label}`}
                    value={selected}
                    options={sellerOptions}
                    onChange={(option) =>
                        setFormData((prev) => ({
                            ...prev,
                            sellerId: option?.value || "", // Only store sellerId
                        }))
                    }
                    isClearable
                />
            </FieldBlock>
        );
    }

    // Species selector
    if (type === "species") {
        const speciesOptions = species.map((s) => ({ value: s.speciesId, label: s.speciesBreed }));
        const selected = speciesOptions.find((o) => o.value === formData.speciesId) || null;

        return (
            <FieldBlock key={name} label={label}>
                <Select
                    styles={selectStyles}
                    className="my-react-select"
                    placeholder={`Select ${label}`}
                    value={selected}
                    options={speciesOptions}
                    onChange={(option) =>
                        setFormData((prev) => ({
                            ...prev,
                            speciesId: option?.value || "", // Only store speciesId
                        }))
                    }
                    isClearable
                />
            </FieldBlock>
        );
    }


    // Toggle Switch
    if (type === "toggle") {
        return (
            <FieldBlock key={name} label={label}>
                <Form.Check
                    type="switch"
                    id={`${name}-switch`}
                    className={`big-toggle-switch ${hasError ? "border-danger" : ""}`}
                    checked={Boolean(formData[name])}
                    onChange={(e) =>
                        setFormData((prev) => ({
                            ...prev,
                            [name]: e.target.checked,
                        }))
                    }
                />
            </FieldBlock>
        );
    }

    // Picklist (React-Select)
    if (type === "picklist" && picklistKey) {
        const rawArray = picklists[picklistKey] || [];
        const options = rawArray.map((item) => ({ value: item, label: item }));
        const selected = options.find((o) => o.value === formData[name]) || null;

        return (
            <FieldBlock key={name} label={label}>
                <Select
                    styles={selectStyles}
                    classNamePrefix="my-select"
                    placeholder={`Select ${label}`}
                    value={selected}
                    options={options}
                    onChange={(option) =>
                        setFormData((prev) => ({ ...prev, [name]: option?.value || "" }))
                    }
                    isClearable
                />
            </FieldBlock>
        );
    }



    // Number Input
    if (type === "number") {
        return (
            <FieldBlock key={name} label={label}>
                <Form.Control
                    type="number"
                    name={name}
                    className={hasError ? "border-danger" : ""}
                    value={formData[name] || ""}
                    onChange={(e) =>
                        setFormData((prev) => ({
                            ...prev,
                            [name]: e.target.value === "" ? "" : Number(e.target.value), // Convert to number
                        }))
                    }
                />
            </FieldBlock>
        );
    }

    // Collection Schedule
    if (type === "collectionSchedule") {
        const daysOfWeek = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

        return (
            <FieldBlock key={name} label={label}>
                {daysOfWeek.map((day) => {
                    const schedule = formData.collectionSchedule?.[day] || { enabled: false, cutoffTime: "" };

                    return (
                        <div key={day} className="mb-2">
                            <Form.Check
                                type="switch"
                                id={`toggle-${day}`}
                                label={day.charAt(0).toUpperCase() + day.slice(1)}
                                checked={schedule.enabled}
                                onChange={(e) => {
                                    setFormData((prev) => ({
                                        ...prev,
                                        collectionSchedule: {
                                            ...prev.collectionSchedule,
                                            [day]: { ...schedule, enabled: e.target.checked }
                                        }
                                    }));
                                }}
                            />
                            {schedule.enabled && (
                                <Form.Control
                                    type="time"
                                    value={schedule.cutoffTime}
                                    onChange={(e) => {
                                        setFormData((prev) => ({
                                            ...prev,
                                            collectionSchedule: {
                                                ...prev.collectionSchedule,
                                                [day]: { ...schedule, cutoffTime: e.target.value }
                                            }
                                        }));
                                    }}
                                />
                            )}
                        </div>
                    );
                })}
            </FieldBlock>
        );
    }

    return null;
}


