// src/components/SkuCreateModal.js

import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { getRegions } from '../services/regionService';
import { getSellers } from '../services/sellerService';
import { getSpecies } from '../services/speciesService';
import { createSku } from '../services/skuService';
import { renderField } from "../utils/renderField";
import { skuSchema } from "../validationSchemas/skuSchema";

// Move the defaultFormData outside the component
const defaultFormData = {
  skuName: '',
  subCategory: '',
  category: '',
  cut: '',
  productImage: '',
  unitSizeName: '',
  packFormat: '',
  packType: '',
  unitSizeDetail: '',
  productSizeMeasure: '',
  process: '',
  price: '',
  uom: '',
  moq: '',
  grade: '',
  showInApp: false,
  availabilityStatus: 'IN_STOCK',
  sellerId: '',
  xeroSku: '',
  speciesId: ''
};

const FIELD_DEFINITIONS = [
  { label: 'SKU Name', name: 'skuName', type: 'text' },
  { label: 'Xero SKU', name: 'xeroSku', type: 'text' },
  { label: 'Species', name: 'speciesId', type: 'species' },
  { label: 'Seller', name: 'sellerId', type: 'seller' },
  { label: 'Show In App', name: 'showInApp', type: 'toggle' },
  { label: 'Availability Status', name: 'availabilityStatus', type: 'picklist', picklistKey: 'availabilityStatus' },
  { label: 'Price', name: 'price', type: 'number' },
  { label: 'UOM', name: 'uom', type: 'picklist', picklistKey: 'uom' },
  { label: 'Category', name: 'category', type: 'picklist', picklistKey: 'category' },
  { label: 'Sub Category', name: 'subCategory', type: 'picklist', picklistKey: 'subCategory' },
  { label: 'Cut', name: 'cut', type: 'picklist', picklistKey: 'cut' },
  { label: 'Unit Size Name', name: 'unitSizeName', type: 'picklist', picklistKey: 'unitSizeName' },
  { label: 'Pack Format', name: 'packFormat', type: 'picklist', picklistKey: 'packFormat' },
  { label: 'Pack Type', name: 'packType', type: 'picklist', picklistKey: 'packType' },
  { label: 'Product Image', name: 'productImage', type: 'text' },
  { label: 'Unit Size Detail', name: 'unitSizeDetail', type: 'text' },
  { label: 'Product Size Measure', name: 'productSizeMeasure', type: 'picklist', picklistKey: 'productSizeMeasure' },
  { label: 'Process', name: 'process', type: 'picklist', picklistKey: 'process' },
  { label: 'MOQ', name: 'moq', type: 'text' },
  { label: 'Grade', name: 'grade', type: 'picklist', picklistKey: 'grade' },
  // { label: 'Region', name: 'regionId', type: 'region' },
];

function SkuCreateModal({ show, onClose }) {
  const [regions, setRegions] = useState([]);
  const [sellers, setSellers] = useState([]);
  const [species, setSpecies] = useState([]);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(defaultFormData);

  // Reset form state when modal opens
  useEffect(() => {
    if (show) {
      setFormData(defaultFormData);
      setErrors({});
      setLoading(false);
    }
  }, [show]); // No need to include defaultFormData here since it's defined outside the component

  useEffect(() => {
    const fetchData = async () => {
      try {
        const regionRes = await getRegions();
        const sellerRes = await getSellers();
        const speciesRes = await getSpecies();

        if (regionRes?.regions) {
          setRegions(regionRes.regions);
        }
        if (sellerRes) {
          const active = sellerRes.filter(s => s.active);
          setSellers(active);
        }
        if (speciesRes) {
          setSpecies(speciesRes.species);
        }
      } catch (err) {
        console.error('Error fetching region/seller/species data:', err);
      }
    };
    fetchData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const { error } = skuSchema.validate(formData, { abortEarly: false });

    if (error) {
      const errorMessages = {};
      error.details.forEach((detail) => {
        errorMessages[detail.path[0]] = detail.message;
      });
      setErrors(errorMessages);
      setLoading(false);
      return;
    }

    try {
      console.log("Creating SKU with data:", formData);
      await createSku(formData);
      console.log("SKU created successfully!");
      onClose();
    } catch (error) {
      console.error("Error creating SKU:", error);
      setErrors({ api: "Failed to create SKU. Please try again." });
    } finally {
      setLoading(false);
    }
  };

  const handleHide = () => {
    const confirmDiscard = window.confirm('Are you sure you want to discard your changes?');
    if (confirmDiscard) {
      onClose();
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleHide}
      backdrop="static"
      keyboard={false}
      centered
      dialogClassName="sku-wide-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Create New SKU</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          {FIELD_DEFINITIONS.map((field) =>
            renderField(field, formData, setFormData, sellers, regions, species, errors)
          )}
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant="secondary"
          className="btn-sm back-button"
          onClick={() => {
            const result = window.confirm('Are you sure you want to discard your changes?');
            if (result) {
              onClose();
            }
          }}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={handleSubmit}
          className="btn-sm back-button"
          disabled={loading}
        >
          {loading ? "Creating..." : "Create"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default SkuCreateModal;
