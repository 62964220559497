import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types'; // ✅ Import PropTypes
import { Container, Button, Spinner, Card, Row, Col, Alert } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { updateSkuVXCallable, getSkuDetails } from '../../services/skuService';
import { getSellers } from '../../services/sellerService';
import { getRegions } from '../../services/regionService';
import { getSpecies } from '../../services/speciesService';
import InputFieldRenderer from '../../components/InputFieldRenderer';
import { filterUpdatableFields, updatableFields } from '../../utils/skuFieldUtils';

const SkuDetails = ({ sku, sellers, regions, species }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedSku, setEditedSku] = useState(sku);
  const [isSaving, setIsSaving] = useState(false);
  const [alert, setAlert] = useState({ show: false, message: '', variant: '' });

  useEffect(() => {
    if (sku && sellers.length && regions.length && species.length) {
      const foundRegion = regions.find((region) => region.regionId === sku.sellerRegionId);
      const foundSeller = sellers.find((seller) => seller.sellerId === sku.sellerId);
      const foundSpecies = species.find((specie) => specie.speciesId === sku.speciesId);

      setEditedSku((prev) => ({
        ...prev,
        sellerId: foundSeller ? foundSeller.sellerId : null,
        sellerRegionId: foundRegion ? foundRegion.regionId : null,
        speciesId: foundSpecies ? foundSpecies.speciesId : null,
      }));
    }
  }, [sku, sellers, regions, species]);

  const handleEditClick = () => setIsEditing(true);
  const handleCancelClick = () => {
    setIsEditing(false);
    setEditedSku(sku);
  };

  const handleSaveClick = async () => {
    setIsSaving(true);
    setAlert({ show: false });

    try {
      const filteredSkuData = filterUpdatableFields(editedSku);
      const response = await updateSkuVXCallable(sku.skuId, filteredSkuData);
      setAlert({ show: true, message: response.message, variant: 'success' });
      window.location.reload();
    } catch (error) {
      console.error('Error saving SKU:', error);
      setAlert({ show: true, message: error.message || 'An error occurred while saving the SKU.', variant: 'danger' });
    } finally {
      setIsSaving(false);
      setIsEditing(false);
    }
  };

  const handleInputChange = (field, value) => {
    setEditedSku((prevSku) => ({
      ...prevSku,
      [field]: value,
    }));
  };

  const renderTableRows = (fields) => {
    return Object.entries(fields).map(([field, value]) => (
      <Row key={field} className="mb-2">
        <Col xs={4} style={{ fontWeight: 'bold', textTransform: 'uppercase', fontSize: '10pt' }}>
          {field.replace(/([A-Z])/g, ' $1')}
        </Col>
        <Col xs={8}>
          <InputFieldRenderer
            field={field}
            value={value}
            handleInputChange={handleInputChange}
            isEditing={isEditing && updatableFields.includes(field)}
            sellers={sellers}
            regions={regions}
            species={species}
          />
        </Col>
      </Row>
    ));
  };

  return (
    <Container className="mt-4">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h1>SKU Details</h1>
        {!isEditing ? (
          <Button variant="primary" onClick={handleEditClick} className="btn-sm">Edit</Button>
        ) : (
          <>
            <Button variant="success" onClick={handleSaveClick} disabled={isSaving} className="btn-sm">
              {isSaving ? (
                <Spinner as="span" animation="border" role="status" aria-hidden="true" />
              ) : (
                'Save'
              )}
            </Button>
            <Button variant="secondary" onClick={handleCancelClick} disabled={isSaving} className="btn-sm ms-2">
              Cancel
            </Button>
          </>
        )}
      </div>

      {alert.show && <Alert variant={alert.variant} className="mt-2">{alert.message}</Alert>}

      <Card className="mb-3">
        <Card.Header>SKU Fields</Card.Header>
        <Card.Body>{renderTableRows(editedSku)}</Card.Body>
      </Card>
    </Container>
  );
};

// ✅ Add PropTypes for validation
SkuDetails.propTypes = {
  sku: PropTypes.shape({
    skuId: PropTypes.string.isRequired,
    sellerId: PropTypes.string,
    sellerRegionId: PropTypes.string,
    speciesId: PropTypes.string,
    skuName: PropTypes.string,
    productImage: PropTypes.string,
    subCategory: PropTypes.string,
    speciesFamily: PropTypes.string,
    packFormat: PropTypes.string,
    cut: PropTypes.string,
    region: PropTypes.object,
    seller: PropTypes.object,
    category: PropTypes.string,
    unitSizeName: PropTypes.string,
    unitSizeDetail: PropTypes.string,
    productSizeMeasure: PropTypes.string,
    packType: PropTypes.string,
    process: PropTypes.string,
    price: PropTypes.number,
    uom: PropTypes.string,
    moq: PropTypes.number,
    grade: PropTypes.string,
    showInApp: PropTypes.bool,
    availabilityStatus: PropTypes.string,
  }).isRequired,
  sellers: PropTypes.arrayOf(PropTypes.object),
  regions: PropTypes.arrayOf(PropTypes.object),
  species: PropTypes.arrayOf(PropTypes.object),
};

const SkuDetailsPage = () => {
  const { skuId } = useParams();
  const [sku, setSku] = useState(null);
  const [sellers, setSellers] = useState([]);
  const [regions, setRegions] = useState([]);
  const [species, setSpecies] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [skuResponse, sellersResponse, regionsResponse, speciesResponse] = await Promise.all([
          getSkuDetails(skuId),
          getSellers('summary'),
          getRegions('summary'),
          getSpecies('summary'),
        ]);

        if (skuResponse) {
          setSku(skuResponse);
        }
        setSellers(sellersResponse?.sellers || []);
        setRegions(regionsResponse?.regions || []);
        setSpecies(speciesResponse?.species || []);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    if (skuId) {
      fetchData();
    } else {
      setLoading(false);
    }
  }, [skuId]);

  if (loading) {
    return (
      <Container className="mt-4 d-flex justify-content-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  return sku ? (
    <SkuDetails sku={sku} sellers={sellers} regions={regions} species={species} />
  ) : (
    <p>SKU not found</p>
  );
};

// ✅ Add PropTypes for validation
SkuDetailsPage.propTypes = {
  skuId: PropTypes.string,
};

export default SkuDetailsPage;
