export const handleAddPackedSku = (producerOrder, setProducerOrder, skuId, debouncedSave) => {
    setProducerOrder((prevOrder) => ({
        ...prevOrder,
        packedSkus: prevOrder.packedSkus ? [...prevOrder.packedSkus, {
            skuId,
            packedSkuId: "", // Start with an empty selection
            packedQuantity: 1,
            packingNotes: "",
        }] : [{
            skuId,
            packedSkuId: "",
            packedQuantity: 1,
            packingNotes: "",
        }]
    }));

};


// ✅ Removes a packed SKU
export const handleRemovePackedSku = (isEditable, setProducerOrder, packedSkuId, debouncedSave) => {
    if (!isEditable) return;
    setProducerOrder((prevOrder) => {
        const updatedPackedSkus = prevOrder.packedSkus.filter((sku) => sku.packedSkuId !== packedSkuId);

        const newOrder = { ...prevOrder, packedSkus: updatedPackedSkus };
        debouncedSave(newOrder); // Trigger auto-save after removing
        return newOrder;
    });
};

export const handleUpdatePackedSku = (producerOrder, setProducerOrder, packedSkuId, field, value, debouncedSave) => {
    setProducerOrder((prevOrder) => {
        const updatedPackedSkus = prevOrder.packedSkus.map((sku) =>
            sku.packedSkuId === packedSkuId 
        ? { ...sku, [field]: field === "packedQuantity" ? Number(value) : value }
               : sku
        );

        const newOrder = { ...prevOrder, packedSkus: updatedPackedSkus };
        debouncedSave(newOrder); // Trigger auto-save
        return newOrder;
    });
};



export const handleSelectPackedSku = (producerOrder, setProducerOrder, packedSkuId, newSkuId, debouncedSave) => {
    setProducerOrder((prevOrder) => {
        const updatedPackedSkus = prevOrder.packedSkus.map((sku) =>
            sku.packedSkuId === packedSkuId ? { ...sku, packedSkuId: newSkuId } : sku
        );

        const newOrder = { ...prevOrder, packedSkus: updatedPackedSkus };
        debouncedSave(newOrder); // Auto-save on SKU selection
        return newOrder;
    });
};

