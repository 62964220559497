// src/services/userService.js

import { httpsCallable } from "firebase/functions";
import { functions } from "../firebaseConfig";  // Assuming firebaseConfig correctly exports initialized Firebase app

export const getUserCompaniesAdmin = async (userId) => {
    if (!userId || typeof userId !== "string") {
        throw new Error("A valid userId must be provided.");
    }

    const getUserCompaniesAdminCallable = httpsCallable(functions, "getUserCompaniesAdmin");
    const response = await getUserCompaniesAdminCallable({ userId });
    return response.data;
};
