import { httpsCallable } from "firebase/functions";
import { functions } from "../firebaseConfig"; // Ensure correct Firebase import

export const getProducerOrderById = async (orderId, producerOrderId) => {
    try {
        const getProducerOrderByIdCallable = httpsCallable(functions, "getProducerOrderById");
        const response = await getProducerOrderByIdCallable({ orderId, producerOrderId });

        if (response.data?.producerOrder) {
            return response.data;
        } else {
            throw new Error("Producer order data not found in response");
        }
    } catch (error) {
        console.error("Error fetching producer order by ID:", error);
        throw error;
    }
};

export const updatePackedSkus = async (sellerId, orderId, producerOrderId, packedSkus) => {
    try {
        const updatePackedSkusCallable = httpsCallable(functions, "updatePackedSkus");

        const payload = {
            sellerId,
            orderId,
            producerOrderId,
            packedSkus,
        };

        //console.log("Sending payload to updatePackedItems:", JSON.stringify(payload, null, 2));

        const response = await updatePackedSkusCallable(payload);

        if (response.data?.success) {
            return response.data;
        } else {
            throw new Error("Failed to update packed skus.");
        }
    } catch (error) {
        console.error("Error updating packed SKUs:", error);
        throw error;
    }
};


export const updatePackedItems = async (payload) => {
    //console.log("Payload before sending:", JSON.stringify(payload, null, 2));
    try {
        const updatePackedItemsCallable = httpsCallable(functions, "updatePackedItems");

        // Pass all payload fields dynamically
        const response = await updatePackedItemsCallable(payload);

        // Handle successful response
        if (response.data.success) {
            return response.data;
        } else {
            throw new Error("Failed to update packed items.");
        }
    } catch (error) {
        throw error;
    }
};

export const updateProducerOrderStatus = async (orderId, producerOrderId, producerOrderStatus) => {
    try {
        // Create a reference to the Firebase callable function
        const updateProducerOrderStatus = httpsCallable(functions, "updateProducerOrderStatus");
        //console.log("updateProducerOrderStatus", updateProducerOrderStatus);

        // Call the function with the required parameters
        const response = await updateProducerOrderStatus({
            orderId,
            producerOrderId,
            producerOrderStatus,
            sendNotificationEmail: true,
        });

        // Check the response from the callable function
        if (response?.data?.success) {
            //console.log("Producer order status updated successfully:", response.data.message);
            return response.data;
        } else {
            throw new Error(response.data.message || "Failed to update producer order status");
        }
    } catch (error) {
        console.error("Error updating producer order status:", error);
        throw error;
    }
};
