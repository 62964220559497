import React, { useState } from 'react';
import { Card, Row, Col, Button, Alert } from 'react-bootstrap';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import { updateProducerOrderStatus } from '../services/producerOrderService';
import ConfirmationModal from './ConfirmationModal';

const ProducerOrderCard = ({ order }) => {
    const navigate = useNavigate();
    const [isEditing, setIsEditing] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState(order.producerOrderStatus);
    const [isSaving, setIsSaving] = useState(false);
    const [alert, setAlert] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const statusOptions = [
        { value: 'AWAITING_PROCESSING', label: 'AWAITING PROCESSING' },
        { value: 'PROCESSING', label: 'PROCESSING' },
        { value: 'NOT_FULFILLED', label: 'NOT FULFILLED' },
        { value: 'PREPARING_FOR_SHIPMENT', label: 'PREPARING FOR SHIPMENT' },
        { value: 'OUT_FOR_DELIVERY', label: 'OUT FOR DELIVERY' },
        { value: 'DELIVERED', label: 'DELIVERED' },
    ];

    const filteredStatusOptions = order.packedSkus
        ? statusOptions
        : statusOptions.filter(option => !['PREPARING_FOR_SHIPMENT', 'OUT_FOR_DELIVERY', 'DELIVERED'].includes(option.value));

    const handleStatusChange = (selectedOption) => setSelectedStatus(selectedOption.value);
    const toggleEdit = () => setIsEditing(!isEditing);
    const resetStatus = () => {
        setSelectedStatus(order.producerOrderStatus);
        setIsEditing(false);
    };

    const handleSave = async () => {
        setIsSaving(true);
        setAlert(null);

        try {
            await updateProducerOrderStatus(order.orderId, order.producerOrderId, selectedStatus);
            setAlert({ type: 'success', message: 'Status updated successfully!' });
            setIsEditing(false);
            setTimeout(() => window.location.reload(), 1000);
        } catch (error) {
            setAlert({ type: 'danger', message: 'Failed to update status. Please try again.' });
        } finally {
            setIsSaving(false);
        }
    };

    const handleConfirmSave = () => {
        setShowModal(false);
        handleSave();
    };

    return (
        <Card className="mb-3">
            <Card.Header>
                <strong>Producer Order:</strong> {order.seller} ({order.sellerId})
            </Card.Header>
            <Card.Body>
                <Row className="mb-3">
                    <Col>
                        <Button
                            variant="primary"
                            className="btn-sm back-button"
                            onClick={() => navigate(`/producer-order-details?orderId=${encodeURIComponent(order.orderId)}&producerOrderId=${encodeURIComponent(order.producerOrderId)}`)}
                        >
                            Edit Packed SKUs
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="d-flex align-items-center">
                            <strong className="me-2">Status:</strong>
                            <Select
                                options={filteredStatusOptions}
                                value={filteredStatusOptions.find(option => option.value === selectedStatus)}
                                onChange={handleStatusChange}
                                isDisabled={!isEditing}
                                className="flex-grow-1"
                            />
                            {!isEditing ? (
                                <Button variant="secondary" onClick={toggleEdit} className="ms-2 btn-sm">
                                    Edit Status
                                </Button>
                            ) : (
                                <>
                                    <Button variant="danger" onClick={resetStatus} className="ms-2 me-2 btn-sm">
                                        Cancel
                                    </Button>
                                    <Button variant="primary" onClick={() => setShowModal(true)} disabled={isSaving} className="btn-sm back-button">
                                        {isSaving ? 'Saving...' : 'Save'}
                                    </Button>
                                </>
                            )}
                        </div>
                        {alert && <Alert variant={alert.type} className="mt-3">{alert.message}</Alert>}
                    </Col>
                </Row>
            </Card.Body>
            <ConfirmationModal
                show={showModal}
                handleClose={() => setShowModal(false)}
                handleConfirm={handleConfirmSave}
                title="Confirm Status Change"
                body="Are you sure you want to change the status?"
            />
        </Card>
    );
};

export default ProducerOrderCard;