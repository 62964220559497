// ✅ Function to format SKU title
export const getSkuTitle = (skuId, skus) => {
    const sku = skus.find((s) => s.skuId === skuId);
    if (!sku) return "Unknown SKU";

    const formattedSku = [
        sku.category,
        sku.cut,
        sku.unitSizeName,
        sku.unitSizeDetail,
        sku.productSizeMeasure,
        sku.packType,
        sku.process,
        sku.grade
    ]
    .filter(Boolean) // Remove empty values
    .join(" / "); // Join with ' | '

    return formattedSku ? `${sku.skuName} (${formattedSku}) (${sku.skuId})` : `(${sku.skuId})`;
};