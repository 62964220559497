const Joi = require('joi');

const picklists = {
    "subCategory": [
        "game",
        "meat",
        "poultry",
        "seafood"
    ],
    "speciesFamily": [
        "anguilliformes",
        "cattle",
        "chicken",
        "crustacean",
        "echinodermate",
        "finfish",
        "game birds",
        "macropod",
        "mollusk",
        "rodentia",
        "sheep"
    ],
    "category": [
        "farmed",
        "wild"
    ],
    "cut": [
        "backstrap",
        "barrel",
        "blade",
        "blood",
        "bone",
        "brisket",
        "chuck",
        "cutlet",
        "eggs",
        "eye-fillet",
        "fat",
        "fillet",
        "flank",
        "flap",
        "fore quarter",
        "fore shank",
        "front arm",
        "Hanger",
        "heart",
        "hind shank",
        "leg",
        "liver",
        "meat",
        "mince",
        "neck",
        "offal",
        "oyster blade",
        "oyster cut",
        "porterhouse",
        "rack",
        "rib-eye",
        "ribs",
        "roe",
        "round",
        "rump",
        "rumps",
        "saddle",
        "scotch",
        "shank",
        "shin",
        "short loin",
        "shoulder",
        "silverside",
        "skirt",
        "striploin",
        "sweetbreads",
        "tail",
        "tenderloin",
        "tongue",
        "topside",
        "trim",
        "whole",
        "wings"
    ],
    "sellerState": [
        "INT",
        "NSW",
        "QLD",
        "SA",
        "TAS",
        "VIC",
        "WA"
    ],
    "product": [
        "AAA",
        "AHA",
        "ASA",
        "BAB",
        "BBB",
        "BFB",
        "BMB",
        "BMC",
        "BOB",
        "BRB",
        "BVB",
        "BWB",
        "BWW",
        "CBC",
        "CCC",
        "CGC",
        "CSC",
        "CTC",
        "EEE",
        "ERL",
        "FCF",
        "FSG",
        "GFG",
        "GSG",
        "HHH",
        "JMJ",
        "KGW",
        "KKK",
        "KSO",
        "KSP",
        "LBL",
        "LEL",
        "LVL",
        "MCM",
        "MSM",
        "OJO",
        "PLS",
        "POP",
        "PSP",
        "QSQ",
        "RER",
        "RFR",
        "RLR",
        "RMR",
        "RRR",
        "RTR",
        "SCS",
        "SES",
        "SFS",
        "SGS",
        "SRO",
        "SSS",
        "STS",
        "TCT",
        "TTT",
        "VCB",
        "VWC",
        "WKP",
        "WPW",
        "WSS",
        "YBF",
        "YKY",
        "YMY"
    ],
    "unitSizeName": [
        "10/15",
        "16/20",
        "21/30",
        "600g",
        "700g",
        "800g",
        "baby",
        "bistro-pacific",
        "bottle/cocktail-sro",
        "extra large",
        "large",
        "large-pacific",
        "large-sro",
        "medium",
        "medium-sro",
        "plate/buffet-pacific",
        "size 2.0",
        "size 2.1",
        "small",
        "small-sro",
        "standard",
        "standard-pacific",
        "U10",
        "u8",
        "variable"
    ],
    "packFormat": [
        "chilled",
        "frozen",
        "live"

    ],
    "packType": [
        "bag",
        "box",
        "jar",
        "loose",
        "tray",
        "vacuum pouch"
    ],
    "size": [
        "1",
        "2",
        "6",
        "7",
        "8",
        "b",
        "e",
        "l",
        "m",
        "p",
        "s",
        "U",
        "u",
        "v"
    ],
    "productSizeMeasure": [
        "gm",
        "kg",
        "mm"
    ],
    "codeProcess": [
        "b",
        "c",
        "e",
        "f",
        "h",
        "H",
        "l",
        "m",
        "n",
        "o",
        "p",
        "r",
        "s",
        "t",
        "w"
    ],
    "process": [
        "bone & rolled",
        "bone in",
        "bone out",
        "cap on",
        "cap on, frenched",
        "closed",
        "cold smoked",
        "cold smoked, sliced",
        "cold smoked, un-sliced",
        "cooked, peeled",
        "denuded",
        "frenched, cap on",
        "gilled gutted scaled",
        "hot smoked",
        "hot smoked, gilled gutted scaled",
        "ice slurry",
        "ice slurry, iki jime",
        "iki jime, ice slurry",
        "pasturised",
        "peeled",
        "raw",
        "skin off",
        "split"
    ],
    "packSizeMeasure": [
        "dz",
        "ea",
        "gr",
        "kg",
        "ltr"
    ],
    "uom": [
        "dz",
        "ea",
        "kg",
        "ltr"
    ],
    "itemType": [
        "beef",
        "broiler",
        "bull",
        "cockerel",
        "lamb"
    ],
    "grade": [
        "AAA Grade",
        "Infinity Blue",
        "Ora King",
        "Premium Grade"
    ],
    "harvestMethod": [
        "bottom trawl",
        "fyke net",
        "hand dive",
        "hand line",
        "hand rake",
        "long line",
        "mesh/gill net, ringing seine",
        "pot",
        "purse seine",
        "trap",
        "wild shot"
    ],
    "farmMethod": [
        "drop line, ocean based",
        "land based, pond raised",
        "land based, RAS (closed pond)",
        "pastured",
        "pastured, chemical free",
        "pastured, free range",
        "rack and bag culture, estuary based",
        "rack and bag culture, ocean based",
        "regenerative, free range",
        "sea cage, ocean based"
    ],
    "certification": [
        "BAP",
        "Halal, A+, BAP",
        "MSC",
        "PROOF"
    ],
    "sustainabilityAssessment": [
        "GoodFish: Amber (Eat Less)",
        "GoodFish: Green (Better Choice)",
        "GoodFish: Not Assessed",
        "GoodFish: Red (Say No)"
    ],
    "showInApp": [
        true,
        false
    ],
    "availabilityStatus": [
        "EXPECTED_TO_HARVEST",
        "IN_STOCK"
    ]
};

const skuSchema = Joi.object({
    availabilityStatus: Joi.string().valid(...picklists.availabilityStatus).required(),
    category: Joi.string().valid(...picklists.category).required(),
    certification: Joi.string().valid(...picklists.certification).allow(null, ""),
    // codeProcess: Joi.string().valid(...picklists.codeProcess).required(),
    // collectionSchedule: Joi.any().required(),
    cut: Joi.string().valid(...picklists.cut).required(),
    farmMethod: Joi.string().valid(...picklists.farmMethod).allow(null, ""),
    // finalLegDeliveryCarrier: Joi.any().required(),
    grade: Joi.string().valid(...picklists.grade).allow(null, ""),
    harvestMethod: Joi.string().valid(...picklists.harvestMethod).allow(null, ""),
    itemType: Joi.string().valid(...picklists.itemType).allow(null, ""),
    moq: Joi.string().allow(null, ""),
    packFormat: Joi.string().valid(...picklists.packFormat).required(),
    packSizeDetail: Joi.string().allow(null, ""),
    packSizeMeasure: Joi.string().valid(...picklists.packSizeMeasure).allow(null, ""),
    packType: Joi.string().valid(...picklists.packType).required(),
    price: Joi.number().required(),
    process: Joi.string().valid(...picklists.process).allow(null, ""),
    // producerCode: Joi.string().required(),
    // product: Joi.string().valid(...picklists.product).required(),
    // productCode: Joi.string().required(),
    productImage: Joi.string().allow(null, ""),
    productSizeMeasure: Joi.string().valid(...picklists.productSizeMeasure).allow(null, ""),
    sellerId: Joi.string().required(),
    // sellerRegionId: Joi.string().required(),
    // sellerState: Joi.string().valid(...picklists.sellerState).required(),
    // sequenceNumber: Joi.number().required(),
    showInApp: Joi.valid(true, false).required(),
    // size: Joi.string().valid(...picklists.size).required(),
    // skuDescription: Joi.string().required(),
    // skuId: Joi.string().required(),
    // skuIdentifier: Joi.string().required(),
    skuName: Joi.string().required(),
    // speciesFamily: Joi.string().valid(...picklists.speciesFamily).required(),
    speciesId: Joi.string().required(),
    subCategory: Joi.string().valid(...picklists.subCategory).required(),
    sustainabilityAssessment: Joi.string().valid(...picklists.sustainabilityAssessment).allow(null, ""),
    unitSizeDetail: Joi.string().allow(null, ""),
    unitSizeName: Joi.string().valid(...picklists.unitSizeName).required(),
    uom: Joi.string().valid(...picklists.uom).required(),
    xeroSku: Joi.string().required(),
});

module.exports = { skuSchema, picklists };
