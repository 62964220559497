import React, { useEffect, useState } from 'react';
import { Container, Table, Spinner, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { getSkus } from '../../services/skuService';
import { FaPlus, FaMinus } from 'react-icons/fa';
import SkuCard from '../../components/SkuCard';
import SkuDetailsTable from '../../components/SkuDetailsTable';
import SkuCreateModal from '../../components/SkuCreateModal'; // <-- import our new modal
import '../../styles.css';

const Skus = () => {
  const [filteredSkus, setFilteredSkus] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expandedRows, setExpandedRows] = useState([]);
  const [showCreateModal, setShowCreateModal] = useState(false); // <-- new state for modal

  const navigate = useNavigate();

  useEffect(() => {
    const fetchSkus = async () => {
      try {
        const skusData = await getSkus();
        setFilteredSkus(skusData);
      } catch (error) {
        console.error('Error fetching SKUs:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSkus();
  }, []);

  const handleRowClick = (skuId) => {
    navigate(`/skusv3/${skuId}`);
  };

  const toggleRowExpansion = (skuId) => {
    setExpandedRows((prevExpandedRows) =>
      prevExpandedRows.includes(skuId)
        ? prevExpandedRows.filter((id) => id !== skuId)
        : [...prevExpandedRows, skuId]
    );
  };

  const columns = [
    { key: 'productImage', label: 'IMAGE' },
    { key: 'skuName', label: 'SKU NAME' },
    { key: 'cut', label: 'CUT' },
    { key: 'sellerName', label: 'SELLER' },
    { key: 'category', label: 'CATEGORY' },
    { key: 'unitSizeName', label: 'UNIT SIZE NAME' },
    { key: 'packType', label: 'PACK TYPE' },
    { key: 'price', label: 'PRICE' },
    { key: 'uom', label: 'UOM' },
    { key: 'showInApp', label: 'IN APP' },
    { key: 'availabilityStatus', label: 'AVAILABLE' },
  ];

  if (loading) {
    return (
      <Container className="content d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border">
          <output className="visually-hidden">Loading...</output>
        </Spinner>
      </Container>
    );
  }

  return (
    <Container className="content">
      <div className="d-flex align-items-center justify-content-between mb-3">
        <h1>SKUs V3 Page</h1>
        {/* Our new "Create" button */}
        <Button variant="primary" onClick={() => setShowCreateModal(true)} className="btn-sm back-button">
          Create New SKU
        </Button>
      </div>

      {/* Scrollable Table Container */}
      <div
        className="d-none d-md-block"
        style={{
          maxHeight: '600px',
          overflowY: 'auto',
          border: '0.5px solid #eee',
          borderRadius: '2px',
        }}
      >
        <Table
          striped
          bordered
          hover
          className="table-responsive"
          style={{ fontSize: '12px', margin: '0px', padding: '0px' }}
        >
          <thead style={{ position: 'sticky', top: 0, background: 'white', zIndex: 2 }}>
            <tr>
              <th style={{ padding: '5px' }}>&nbsp;</th>
              {columns.map((col) => (
                <th key={col.key} style={{ whiteSpace: 'nowrap', padding: '5px' }}>
                  {col.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {filteredSkus.map((sku) => (
              <React.Fragment key={sku.skuId}>
                <tr style={{ cursor: 'pointer' }} onClick={() => handleRowClick(sku.skuId)}>
                  <td
                    style={{ whiteSpace: 'nowrap', padding: '0px' }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Button
                      variant="link"
                      size="sm"
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleRowExpansion(sku.skuId);
                      }}
                      style={{ paddingTop: 0, paddingBottom: 0, margin: 0 }}
                    >
                      {expandedRows.includes(sku.skuId) ? <FaMinus /> : <FaPlus />}
                    </Button>
                  </td>
                  <td className="col-productImage" style={{ whiteSpace: 'nowrap', padding: '5px' }}>
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip
                          id={`tooltip-${sku.skuId}`}
                          style={{ padding: 0 }}
                        >
                          <div
                            style={{
                              backgroundColor: '#ffffff',
                              padding: '8px',
                              borderRadius: '8px',
                              border: '1px solid #ddd',
                              overflow: 'hidden',
                            }}
                          >
                            <img
                              src={`https://assets.harveststack.co/pictures/skus/medium/${sku.productImage}`}
                              alt={sku.skuName}
                              style={{
                                width: '200px',
                                height: '200px',
                                objectFit: 'contain',
                                display: 'block',
                                borderRadius: '4px',
                              }}
                            />
                          </div>
                        </Tooltip>
                      }
                    >
                      <img
                        src={`https://assets.harveststack.co/pictures/skus/small/${sku.productImage}`}
                        alt="&nbsp;"
                        style={{ width: '16px', height: '16px', objectFit: 'contain', borderRadius: '4px' }}
                      />
                    </OverlayTrigger>
                  </td>
                  {columns.slice(1).map((col) => (
                    <td key={col.key} className={`col-${col.key}`} style={{ whiteSpace: 'nowrap', padding: '5px' }}>
                      {col.key === 'showInApp' ? (
                        (() => {
                          const showInAppValue = sku[col.key] ? 'Yes' : 'No';
                          return showInAppValue;
                        })()
                      ) : (
                        sku[col.key]?.toString() || 'N/A'
                      )}
                    </td>
                  ))}
                </tr>
                {expandedRows.includes(sku.skuId) && (
                  <tr>
                    <td colSpan="16" style={{ padding: '0px' }}>
                      <SkuDetailsTable sku={sku} />
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </Table>
      </div>

      {/* Card layout for mobile devices */}
      <div className="d-block d-md-none">
        {filteredSkus.map((sku) => (
          <SkuCard key={sku.skuId} sku={sku} onClick={() => handleRowClick(sku.skuId)} />
        ))}
      </div>

      {/* Modal for creating SKU */}
      <SkuCreateModal
        show={showCreateModal}
        onClose={() => setShowCreateModal(false)}
      />
    </Container>
  );
};

export default Skus;
