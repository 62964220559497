import { initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getFirestore } from "firebase/firestore";

// Firebase configuration using environment variables
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const functions = getFunctions(app, process.env.REACT_APP_FIREBASE_FUNCTIONS_REGION);
const firestore = getFirestore(app);

// Check if we should connect to the emulator
if (process.env.REACT_APP_USE_EMULATOR === 'true') {
  // Connect to the Auth emulator
  const authEmulatorHost = process.env.REACT_APP_FIREBASE_AUTH_EMULATOR_HOST || 'localhost:9099';
  connectAuthEmulator(auth, `http://${authEmulatorHost}`);
  
  // Connect to the Functions emulator
  const functionsEmulatorHost = process.env.REACT_APP_FIREBASE_FUNCTIONS_EMULATOR_HOST || 'localhost:5001';
  const [host, port] = functionsEmulatorHost.split(':');
  connectFunctionsEmulator(functions, host, parseInt(port, 10));

  //console.log(`Connected to Firebase emulators: Auth at ${authEmulatorHost}, Functions at ${functionsEmulatorHost}`);
}

// Export auth, functions, and firestore
export { auth, functions, firestore };
