import React from "react";
import ProducerOrderCard from "./ProducerOrderCard"; // Import the new component

const ProducerOrderComponent = ({ producerOrders, isEditing }) => {

    return (
        <>
            {producerOrders?.map((order, index) => (
                <ProducerOrderCard
                    key={index}
                    order={order}
                    
                    isEditing={isEditing} // Pass down isEditing state
                />
            ))}
        </>
    );
};

export default ProducerOrderComponent;
