// src/services/companyService.js

import { httpsCallable } from "firebase/functions";
import { functions } from "../firebaseConfig";  // Assuming firebaseConfig correctly exports initialized Firebase app

export const getAllCompanies = async () => {
  const getAllCompaniesCallable = httpsCallable(functions, 'getAllCompanies');
  const response = await getAllCompaniesCallable();
  return response.data;  // This should return an object like { result: { success: true, companies: [...] } }
};

export const getCompanyById = async (companyId) => {
  const getCompanyByIdCallable = httpsCallable(functions, 'getCompanyById');
  const response = await getCompanyByIdCallable({ companyId });
  return response.data.company;
};


export const updateCompany = async (companyId, companyData) => {
  const updateCompanyCallable = httpsCallable(functions, 'updateCompany');
  const response = await updateCompanyCallable({ companyId, ...companyData });
  return response.data;
};



// NEW ONES

// NEW ONES

export const getCompanies = async (scope) => {
  try {
    const getCompanies = httpsCallable(functions, 'getCompanies');
    const response = await getCompanies({ scope }); // Pass the scope parameter
    return response.data;
  } catch (error) {
    console.error('Error fetching companies:', error);
    throw new Error('Failed to fetch companies');
  }
};