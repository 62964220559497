import React, { useEffect, useState } from 'react';
import { Container, Card, Button, Form, Alert, Row, Col, Spinner } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { getRegionById, updateRegion } from '../../services/regionService'; // Import region service
import { FaArrowLeft, FaEdit, FaSave, FaTimes } from 'react-icons/fa';
import '../../styles.css';

const RegionDetails = () => {
  const [editableRegion, setEditableRegion] = useState({});
  const [backupRegion, setBackupRegion] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [saveError, setSaveError] = useState(false);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRegionDetails = async () => {
      const query = new URLSearchParams(location.search);
      const regionId = query.get('regionId');

      try {
        const regionData = await getRegionById(regionId);

        if (!regionData) {
          throw new Error('Region data not found in the response');
        }

        setEditableRegion(regionData);
        setBackupRegion(regionData);
      } catch (error) {
        console.error('Error fetching Region details:', error);
        setSaveError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchRegionDetails();
  }, [location]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditableRegion({ ...editableRegion, [name]: value });
  };

  const handleEdit = () => {
    setBackupRegion(editableRegion); // Save a copy of the current state
    setIsEditing(true);
  };

  const handleCancel = () => {
    setEditableRegion(backupRegion); // Restore the backup state
    setIsEditing(false);
  };

  const handleSave = async () => {
    setIsSaving(true);
    setIsSaved(false);
    setSaveError(false);
    try {
      await updateRegion(editableRegion.regionId, {
        regionName: editableRegion.regionName,
        stateName: editableRegion.stateName,
        country: editableRegion.country,
        shortDescription: editableRegion.shortDescription,
        fullDescription: editableRegion.fullDescription,
        regionDescription: editableRegion.regionDescription,
        regionImages: editableRegion.regionImages,
        countryCode: editableRegion.countryCode,
        regionCode: editableRegion.regionCode,
        stateCode: editableRegion.stateCode,
        status: editableRegion.status, // Including status for completeness
      });
      setIsSaved(true);
    } catch (error) {
      console.error('Error saving Region:', error);
      setSaveError(true);
    } finally {
      setIsSaving(false);
      setIsEditing(false);
    }
  };

  if (loading) {
    return (
      <Container className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  const regionInformation = [
    { label: 'Region Name', key: 'regionName', editable: true },
    { label: 'State Name', key: 'stateName', editable: true },
    { label: 'Country', key: 'country', editable: true },
    { label: 'Country Code', key: 'countryCode', editable: true },
    { label: 'Region Code', key: 'regionCode', editable: true },
    { label: 'State Code', key: 'stateCode', editable: true },
    { label: 'Short Description', key: 'shortDescription', as: 'textarea', rows: 3, editable: true },
    { label: 'Full Description', key: 'fullDescription', as: 'textarea', rows: 6, editable: true },
    { label: 'Region Description', key: 'regionDescription', as: 'textarea', rows: 6, editable: true },
    { label: 'Status', key: 'status', editable: true },
  ];

  const auditFields = [
    { label: 'Region ID', key: 'regionId', editable: false },
  ];

  const renderFields = (fields) => {
    return (
      <Row>
        {fields.map(({ label, key, as, rows, editable }) => (
          <Col md={as ? 12 : 6} key={key} className="mb-3">
            <Form.Group>
              <Form.Label>{label}</Form.Label>
              <Form.Control
                as={as || 'input'}
                rows={rows || 1}
                name={key}
                value={editableRegion[key] || ''}
                readOnly={!isEditing || !editable}
                onChange={editable ? handleInputChange : undefined}
              />
            </Form.Group>
          </Col>
        ))}
      </Row>
    );
  };

  const renderImages = () => {
    return (
      <Row>
        {editableRegion.regionImages && editableRegion.regionImages.length > 0 ? (
          editableRegion.regionImages.map((image, index) => (
            <Col key={index} md={6} className="mb-3">
              <img src={image} alt="region" className="img-fluid" />
            </Col>
          ))
        ) : (
          <Col>
            <p>No images available</p>
          </Col>
        )}
      </Row>
    );
  };

  return (
    <Container className="content">
      <h1>Region Details</h1>
      <div className="d-flex justify-content-between mb-3">
                <Button variant="secondary" onClick={() => navigate("/orders")} className="btn-sm back-button">
                <FaArrowLeft className="me-2" style={{ fontSize: "0.875rem" }} />
                    Back
                </Button>
        <div>
          {isEditing ? (
            <>
              <Button variant="danger" onClick={handleCancel} disabled={isSaving} className="me-3">
                <FaTimes className="me-2 mb-1" />
                Cancel
              </Button>
              <Button 
                variant="primary" 
                onClick={handleSave} 
                disabled={isSaving}
              >
                {isSaving ? (
                  <span className="spinner-border spinner-border-sm"></span>
                ) : (
                  <>
                    <FaSave className="me-2 mb-1" />
                    Save
                  </>
                )}
              </Button>
            </>
          ) : (
            <Button variant="secondary" onClick={handleEdit} disabled={isSaving}>
              <FaEdit className="me-2 mb-1" />
              Edit
            </Button>
          )}
        </div>
      </div>
      {isSaved && <Alert variant="success">Record has been saved</Alert>}
      {saveError && <Alert variant="danger">Could not save record</Alert>}

      <Card className="mb-3">
        <Card.Header>Region Information</Card.Header>
        <Card.Body>
          <Form>{renderFields(regionInformation)}</Form>
        </Card.Body>
      </Card>

      <Card className="mb-3">
        <Card.Header>Region Images</Card.Header>
        <Card.Body>
          {renderImages()}
        </Card.Body>
      </Card>

      <Card className="mb-3">
        <Card.Header>Audit Information</Card.Header>
        <Card.Body>
          <Form>{renderFields(auditFields)}</Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default RegionDetails;
